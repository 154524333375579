import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Callback = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        (async () => {

            if (auth.error) {
                console.error('OIDC Error:', auth.error);
                navigate("/");
            }

            if (auth.isLoading) {
                return;
            }
            if (auth.user && auth.user.id_token) {
                navigate("/search");
            }
        })();
    }, [auth.user, auth.isLoading, auth.error, navigate]);

    return null;
};

export default Callback;
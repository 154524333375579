import React from "react";

interface ToastProps {
  message: string | null;
  isVisible: boolean;
  onClose: () => void;
  type?: "success" | "danger" | "warning"; // You can add more types if needed
}

const Toast: React.FC<ToastProps> = ({ message, isVisible, onClose, type = "success" }) => {
  let typeClass = "";
  switch (type) {
    case "success":
      typeClass = "text-bg-success";
      break;
    case "danger":
      typeClass = "text-bg-danger";
      break;
    case "warning":
      typeClass = "text-bg-warning";
      break;
    default:
      return;
  }
  const toastClass = `toast align-items-center border-0 show ${typeClass}`;

  return (
    <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11, display: isVisible ? "block" : "none" }}>
      <div className={toastClass} role="alert" aria-live="assertive" aria-atomic="true" style={{ width: "auto" }}>
        <div className="d-flex justify-content-between align-items-center toast-body">
          <span>{message}</span>
          <button type="button" className="btn-close btn-close-white ms-3" aria-label="Close" style={{ fontSize: "1rem" }} onClick={onClose}></button>
        </div>
      </div>
    </div>
  );
};

export default Toast;

import {LS_ACCESS_TOKEN, LS_ID_TOKEN, LS_LOGOUT_TOKEN, LS_USER_PHOTO} from "../../constants/EsapConstants.ts";
import {jwtDecode} from "jwt-decode";

class AccessTokenService {

    protected tokens: Record<string, string>;
    private tokenReadyResolvers: ((value: string | null) => void)[] = [];
    constructor() {
        this.tokens = {};
    }

    setAccessToken = (token: Record<string, string>) => {
        this.tokens = { ...this.tokens, ...token };

        // Resolve any pending token requests
        if (this.tokenReadyResolvers.length > 0) {
            this.tokenReadyResolvers.forEach((resolve) => resolve(this.tokens[LS_ACCESS_TOKEN] || null));
            this.tokenReadyResolvers = []; // Clear resolvers after resolving
        }
    };

    getAccessTokenFromLS = (tokenType: string) : string | null => {
        const token = localStorage.getItem(tokenType);
        if (token) {
            this.tokens[tokenType] = token;
        }
        return token;
    }
    waitForToken = async (tokenType: string = LS_ACCESS_TOKEN): Promise<string | null> => {
        const token = this.getToken(tokenType);
        if (token) {
            return token; // If the token is already available, return it immediately
        }

        // Otherwise, wait for the token to be set
        return new Promise((resolve) => {
            this.tokenReadyResolvers.push(resolve);
        });
    };
    getToken = (tokenType: string =  LS_ACCESS_TOKEN) => {
        const accessToken = this.tokens[tokenType];
        if (!accessToken) {
            return null;
        }
        if (this.isTokenExpired(accessToken)) {
            delete this.tokens[tokenType];
            return null;
        }
        return accessToken;
    }

    isTokenExpired = (token: string) => {
        if (!token) return true;
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decodedToken && decodedToken.exp) {
                return decodedToken.exp < currentTime;
            }
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    }

    clearAccessTokens = () => {
        const logoutToken = this.tokens[LS_LOGOUT_TOKEN];

        delete this.tokens[LS_ACCESS_TOKEN];
        delete this.tokens[LS_LOGOUT_TOKEN];
        delete this.tokens[LS_USER_PHOTO];
        delete this.tokens[LS_ID_TOKEN];
        return logoutToken;
    }

    clearOidcTokens = () => {
        for (const key in localStorage) {
            if (key.startsWith('oidc.')) {
                localStorage.removeItem(key);
            }
        }
    }

}
const accessTokenService = new AccessTokenService();
export default accessTokenService;
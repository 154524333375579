import { DatasetResponse } from "../../../model/InfoviewerResult";
import {useTranslation} from "react-i18next";
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
interface CommonMetadataProps {
    datasetResponse: DatasetResponse;
    taxonomySectorTranslationKey ?: string;
    legalFrameworkTranslationKey ?: string;
    rgltDataClssctnTraslationKey ?: string;
}

const textInfoWithTooltip = (content : string|undefined) => {
    if (!content) return '';
    const length=30;
    const isTruncated = content.length > length;
    const displayedContent = isTruncated ? content.slice(0, length) + '...' : content;
    
    return (
      <OverlayTrigger  placement="top" overlay={<Tooltip>{content}</Tooltip>}>
             <span>{displayedContent}</span>
      </OverlayTrigger>);
};
  

const CommonMetaData: React.FC<CommonMetadataProps>=({ datasetResponse,taxonomySectorTranslationKey,legalFrameworkTranslationKey,rgltDataClssctnTraslationKey})=>{
    const {t} = useTranslation();

    return (
        <>
        <dt>COLLECTION BODY:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.colltnBody)}</dd>
        <dt>ENTITY COUNTRY:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.rltdNttyLglPrsnRegdCtry)}</dd>
        <dt>ENTITY SECTOR:</dt>      
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.rltdNttyLglPrsnSctr.map(sectorData => {
          const sector = sectorData.nacetXnmySctr;
          return taxonomySectorTranslationKey && sector? t(`${taxonomySectorTranslationKey}_${sector}`): sector;
          }).join(', '))}</dd>
        <dt>TYPE OF INFORMATION:</dt>
        <dd>{textInfoWithTooltip(datasetResponse.metadata.rgltryDataTp.map(regulatoryData =>{
          const classification = regulatoryData.clssfctn;
          return rgltDataClssctnTraslationKey && classification? t(`${rgltDataClssctnTraslationKey}_${classification}`):classification;
          }).join(', '))}</dd>
        <dt>LEGAL FRAMEWORK:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.rgltryDataTp.map(regulatoryType => {
          const framework = regulatoryType.lglFrmwk;
          return legalFrameworkTranslationKey && framework? t(`${legalFrameworkTranslationKey}_${framework}`) : framework;
          }).join(', '))}
        </dd>
        <dt>SUBMISSION DATE/TIME:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.submissnDtTm)}</dd>
        <dt>HOME MEMBER STATE:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.homeCtry)}</dd>
        <dt>HOST MEMBER STATE:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.hstCtry.join(', '))}</dd> 
        <dt>VERSION:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.metadata.vrsn)}</dd>     
        <dt>RELATED DOCUMENTS:</dt>
        <dd>{textInfoWithTooltip(datasetResponse?.files?.map(file=>file?.fileName).join(', '))}</dd>
        </>
    );
};
export default CommonMetaData;
import React from 'react';
import { InputGroup, Form, Button, ListGroup, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {SEARCH_PLACEHOLDER_TEXT, SEARCH_TOOLTIP_TEXT} from "@/constants/EsapConstants.ts";
import strings from "@/loc/strings.ts";

interface FreeTextInputProps {
    query: string;
    handleSuggest: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSuggestionClick: (suggestion: string) => void;
    suggestions: string[];
    renderTooltip: (message: string) => JSX.Element;
}

const FreeTextInput: React.FC<FreeTextInputProps> = ({
                                                     query,
                                                     handleSuggest,
                                                     handleSuggestionClick,
                                                     suggestions,
    renderTooltip
                                                 }) => {
    const { t } = useTranslation();

    return (
        <>
            <InputGroup className="mb-3">
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(t(SEARCH_TOOLTIP_TEXT))}
                >
                    <Button variant="link" className="btn-sm border-end FormHelp">
                        <i className="bi bi-info-lg" aria-hidden="true"></i>
                        <span className="visually-hidden">Help with this field</span>
                    </Button>
                </OverlayTrigger>
                <Form.Control
                    type="text"
                    placeholder={t(SEARCH_PLACEHOLDER_TEXT)}
                    aria-label="Free-text search"
                    name={strings.freeText}
                    value={query}
                    onChange={handleSuggest}
                />
                {query && (
                    <Button
                        variant="link-dark"
                        className="btn-sm bg-white border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                        onClick={() =>
                            handleSuggest({
                                target: { name: strings.freeText, value: '' },
                            } as React.ChangeEvent<HTMLInputElement>)
                        }
                    >
                        <i className="bi bi-x fs-6" aria-hidden="true"></i>
                        <span className="visually-hidden">Clear text</span>
                    </Button>
                )}
            </InputGroup>
            {suggestions.length > 0 && (
                <ListGroup>
                    {suggestions.map((suggestion, index) => (
                        <ListGroup.Item
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            className="suggestion-item"
                        >
                            {suggestion}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
        </>
    );
};

export default FreeTextInput;
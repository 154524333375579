import { Accordion, Button, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import React from "react";


import {
    HS_DATASET_STATUS,
    HS_ELECTRONICALLY_SEALED, HS_ERROR, HS_FILE_DETAILS,
    HS_FILENAME, HS_VALIDATION_OUTCOME, HS_VALIDATION_STATUS
} from "../../constants/EsapConstants.ts";

interface DetailsModalProps {
    show: boolean;
    handleClose: () => void;
}

import { Details } from "./types.tsx";

import { fetchDetails } from "@/services/HistoryOfSubmission/HistoryOfSubmissionService.ts";
import {useTranslation} from "react-i18next";

const HistoryDetailsModal = (props: DetailsModalProps) => {
    const [show, setShow] = useState(false);
    const [details, setDetails] = useState<Details | null>(null); // Use a single Details object or null
    const handleClose = props.handleClose;
    const { t } = useTranslation();

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        // Fetch results from the service
        const loadDetails = async () => {
            try {
                const data = await fetchDetails(null);
                setDetails(data); // Assuming the service returns a single object
            } catch (error) {
                console.error("Error loading results:", error);
            }
        };

        loadDetails();
    }, []);

    if (!details) {
        return (
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header>
                    <Modal.Title>{t(HS_FILE_DETAILS)}</Modal.Title>
                    <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    <p>Loading...</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header>
                <Modal.Title>{t(HS_FILE_DETAILS)}</Modal.Title>
                <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body>
                <div className="text-bg-light p-3 mb-3 rounded-2">
                    <dl className="DList DList-Compact DList-Separators DList-6-6 DList-xl-3-9 mb-0">
                        <dt>{t(HS_FILENAME)}:</dt>
                        <dd>{details.Filename}</dd>
                        <dt>{t(HS_FILENAME)}:</dt>
                        <dd>{details.FeedbackFileName}</dd>
                        <dt>{t(HS_VALIDATION_OUTCOME)}:</dt>
                        <dd>{details.ValidationOutcome}</dd>
                    </dl>
                </div>
                <Accordion defaultActiveKey="0">
                    {details.Datasets.map((dataset, datasetIndex) => (
                        <Accordion.Item eventKey={String(datasetIndex)} key={datasetIndex}>
                            <Accordion.Header>{dataset.DatasetTitle}</Accordion.Header>
                            <Accordion.Body>
                                <dl className="DList DList-Compact DList-Separators DList-6-6 DList-xl-3-9 mb-0">
                                    <dt>{t(HS_ELECTRONICALLY_SEALED)}:</dt>
                                    <dd>{dataset.ESealed ? "True" : "False"}</dd>
                                    <dt>{t(HS_DATASET_STATUS)}:</dt>
                                    <dd>{dataset.DatasetStatus}</dd>
                                    <dt>{t(HS_VALIDATION_STATUS)}:</dt>
                                    <dd>{dataset.ValidationStatus}</dd>
                                    {dataset.Error &&
                                        dataset.Error.map((error, errorIndex) => (
                                            <React.Fragment key={errorIndex}>
                                                <dt>{t(HS_ERROR)}:</dt>
                                                <dd>
                                                    {error.ErrorCode}{" "}
                                                    <span className="text-secondary fw-normal ms-2">
                                                    ({error.Description})
                                                  </span>
                                                </dd>
                                            </React.Fragment>
                                        ))}
                                </dl>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default HistoryDetailsModal;

import accessTokenService from "../AccessTokenService/AccessTokenService.ts";
import { downloadServiceUrl, infoviewerUrl, monitoringUrl } from "../ServiceEndpoints.ts";

export async function fetchWrapper(url: string, options: RequestInit = {}) {
    const token = accessTokenService.getToken();
    const headers: Record<string, string> = {
        ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        ...options.headers as Record<string, string>,
    };

    const fetchOptions: RequestInit = {
        ...options,
        headers,
    };

    const finalUrl = adjustUrlSecurityLevel(url, token);

    try {
        return await fetch(finalUrl, fetchOptions);
    } catch (error) {
        console.error("Fetch error:", error);
        throw error;
    }
}

function adjustUrlSecurityLevel(url: string, token: string | null): string {
    if (url.startsWith(infoviewerUrl)) {
        return replaceSecurityLevel(url, infoviewerUrl, token);
    } else if (url.startsWith(downloadServiceUrl)) {
        return replaceSecurityLevel(url, downloadServiceUrl, token);
    }
    if(url.startsWith(monitoringUrl)) {
        // Monitoring URLs.
        return replaceSecurityLevel(url, monitoringUrl, token);
    }

    return url;
}

function replaceSecurityLevel(url: string, startWithUrl: string, token: string | null) {
    return startWithUrl.replace('{__security_level_context__}', token ? 'secure' : 'public').replace('{__security_level__}', token ? 'secure' : 'public') + url.substring(startWithUrl.length);
}

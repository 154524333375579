import { DatasetResponse } from "../../../model/InfoviewerResult";
import CommonMetaData from "./CommonMetadata";
import {INFOVIEWER_PASSPORTED_INDICATOR_TRUE_TEXT,INFOVIEWER_PASSPORTED_INDICATOR_FALSE_TEXT, INFOVIEWER_PRSNL_DATA_YES_TEXT, INFOVIEWER_PRSNL_DATA_NO_TEXT, INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT } from "../../../constants/EsapConstants";
interface DATPRIMetadataProps {
    datasetResponse: DatasetResponse;
}


const DATPRIMetadata: React.FC<DATPRIMetadataProps>=({ datasetResponse})=>{

    return (
    
        <>
        <CommonMetaData datasetResponse={datasetResponse } taxonomySectorTranslationKey={INFOVIEWER_METADATA_TAXONOMY_SECTOR_KEY_PREFIX_TEXT} />
        <dt>APPROVAL OR FILING DATE</dt>
        <dd>{datasetResponse?.xmlMetadata.approvalOrFilingDateFrom} - {datasetResponse?.xmlMetadata.approvalOrFilingDateTo}</dd>
        <dt>EU GROWTH PROSPECTUS CATEGORY</dt>
        <dd>{datasetResponse?.xmlMetadata.euGrowthProspectusCategory}</dd>
        <dt>SECURITIES TYPE</dt>
        <dd>{datasetResponse?.xmlMetadata.securitiesType}</dd>
        <dt>CONSIDERATION OFFERED</dt>
        <dd>{datasetResponse?.xmlMetadata.considerationOffered}</dd>
        <dt>PROSPECTUS TYPE</dt>
        <dd>{datasetResponse?.xmlMetadata.prospectusType}</dd>
        <dt>PASSPORTED INDICATOR</dt>
        <dd>{datasetResponse?.xmlMetadata.passportedIndicator ? INFOVIEWER_PASSPORTED_INDICATOR_TRUE_TEXT : INFOVIEWER_PASSPORTED_INDICATOR_FALSE_TEXT}</dd>
        <dt>ISIN:</dt>
        <dd>{datasetResponse?.metadata.rltdIdrIsin ? datasetResponse?.metadata.rltdIdrIsin : null}</dd>
        <dt>PERSONAL DATA</dt>
        <dd>{datasetResponse?.metadata.prsnlData?INFOVIEWER_PRSNL_DATA_YES_TEXT : INFOVIEWER_PRSNL_DATA_NO_TEXT}</dd>
             </>
    );};


  
    export default DATPRIMetadata;
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import SearchResults from "../SearchResults/SearchResults";
import {searchForm} from "@/services/SearchService/SearchService.ts";
import {SearchFilter} from "@/model/SearchFilter.ts";
import {SearchResult} from "@/model/SearchResult.ts";
import {TypeOption} from "@/model/TypeOption.ts";
import { Col, Container, Form, Row, Button, Alert, Stack, Collapse, Tooltip } from "react-bootstrap";
import {backendSearchPublicUrl, drupalIconsUrl} from "@/services/ServiceEndpoints.ts";
import strings from "../../loc/strings.ts";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {OnChangeValue} from 'react-select';
import {VisibilityFilter} from "@/model/VisibilityFilter.ts";
import SearchField from "../SearchField/SearchField.tsx";
import {useTranslation} from "react-i18next";
import {
    SEARCH_FILTERS_TEXT,
    SEARCH_HEADER_TEXT,
    SEARCH_BUTTON_TEXT,
    SEARCH_CLEAR_BUTTON_TEXT,
    SEARCH_MORE_FILTERS_TEXT,
    FILTER_KEY_MAPPING,
    IMPORTANT_ANNOUNCEMENTS_LABEL, IMPORTANT_ANNOUNCEMENTS_TEXT, SEARCH_NO_RESULTS, Roles, CustomEvents
} from "@/constants/EsapConstants.ts";
import {fetchWrapper} from "@/services/FetchWrapperService/FetchWrapper.ts";
import {useUserInfo} from "@/hooks/useUserInfo.ts";
import {UserInfo} from "@/model/UserInfo.ts";
import { DataFlowType } from "@/model/DataFlowType.ts";
import { getCartLimit } from "@/services/CartSevice/BulkDownloadService.ts";
import {uuidGeneratorWithTimestamp} from "@/utils/uuidGenerator.ts";
import {useAppInsights} from "@/hooks/useAppInsights.ts";
import {SpecificFilter} from "@/model/SpecificFilter.ts";
import { MultiSelectRef } from "@/components/ui/multi-select.tsx";
import {SingleSelectRef} from "@/components/ui/select.tsx";
import FreeTextInput from "@/components/FreeTextInput/FreeTextInput.tsx";
import AppliedFilters from "@/components/AppliedFilters/AppliedFilters.tsx";

export interface SearchState {
    searchFormFields: SearchFilter
}

interface DatePickers {
    [key: string]: React.RefObject<DatePicker>;
}

type InputValueType = string | DataFlowType; // Define an input value type

interface CustomChangeEvent {
    target: {
        name: string;
        value: InputValueType; // Accept both string and DataFlowType
    };
}

const Search: React.FC = () => {
    const {t} = useTranslation();
    const {user} = useUserInfo();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    const [cartLimit, setCartLimit] = useState<number>(5);
    const multiSelectRefs = useRef<{ [key: string]: MultiSelectRef | null }>({});
    const singleSelectRefs = useRef<{ [key: string]: SingleSelectRef | null }>({});

    const translateFilterKey = (key: string): string => {
        return FILTER_KEY_MAPPING[key] || key;
    };

    useEffect(() => {

        setUserInfo(user);

    }, [user, setUserInfo]);

    const hasRoles = useCallback((roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    }, [userInfo]);

    useEffect(() => {
        const fetchCartLimit = async () => {
          try {
            if (hasRoles([Roles.DOWNLOAD_SERVICE_ROLE])) {
                const { cartLimit } = await getCartLimit();
                setCartLimit(cartLimit);
            }
          } catch (error) {
            console.error("Failed to fetch cart limit:", error);
          }
        };

        fetchCartLimit();
      }, [hasRoles]);

    const [showResults, setShowResults] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [fieldConfigurations, setFieldConfigurations] = useState<VisibilityFilter[]>(
        [{
            category: {
                categoryName: ""
            },
            fieldLabel: "",
            fieldName: "",
            fieldType: "",
            isMulti: false,
            options: [],
            fullWidth: false
        }]
    );
    const [searchFieldsState, setSearchFieldsState] = useState<SearchState>({
        searchFormFields: {
            freeText: "",
            typeOfInformation: [],
            entityLEI: "",
            entityName: "",
            sizeOfEntity: { value: '' },
            submissionDateFrom: "",
            submissionDateTo: "",
            dateOrPeriodCoveredFrom: "",
            dateOrPeriodCoveredTo: "",
            submittingEntityName: "",
            submittingEntityLEI: "",
            sizeOfSubmittingEntity: [],
            leiStatus: [],
            countryOfRegisteredOffice: [],
            industrySector: [],
            originalLanguage: [],
            legalFramework: [],
            collectionBodyName: [],
            homeMemberStateOfEntity: [],
            hostMemberStateOfEntity: [],
            isin: "",
            fisn: "",
            voluntaryInfoFlag: false,
            historicalInfoFlag: false
        }
    })
    const [formFieldsState, setFormFieldsState] = useState<SearchState>({
        searchFormFields: {
            freeText: "",
            typeOfInformation: [],
            entityLEI: "",
            entityName: "",
            sizeOfEntity: { value: '' },
            submissionDateFrom: "",
            submissionDateTo: "",
            dateOrPeriodCoveredFrom: "",
            dateOrPeriodCoveredTo: "",
            submittingEntityName: "",
            submittingEntityLEI: "",
            sizeOfSubmittingEntity: [],
            leiStatus: [],
            countryOfRegisteredOffice: [],
            industrySector: [],
            originalLanguage: [],
            legalFramework: [],
            collectionBodyName: [],
            homeMemberStateOfEntity: [],
            hostMemberStateOfEntity: [],
            isin: "",
            fisn: "",
            voluntaryInfoFlag: false,
            historicalInfoFlag: false
        }
    })
    const [specificFilters, setSpecificFilters] = useState<SpecificFilter[]>([
        {
            name: "",
            field_display: "",
            field_label: "",
            field_icon_class: "",
            field_disclaimer: "",
        }
    ]);
    const [searchResult, setSearchResult] = useState<SearchResult>({results: [], totalCount: 0});
    const [query, setQuery] = useState<string>("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [openSearchFilters, setOpenSearchFilters] = useState(false);
    const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);
    const [resetItemsPerPage, setResetItemsPerPage] = useState<boolean>(false);
    const [sortBy] = useState<string>("relevance");
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [pageNumber] = useState<number>(1);
    const [eventUUID, setEventUUID] = useState<string | null>("");
    const appInsights = useAppInsights();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const datePickers: DatePickers = {
        submissionDateFrom: useRef(null),
        submissionDateTo: useRef(null),
        dateOrPeriodCoveredFrom: useRef(null),
        dateOrPeriodCoveredTo: useRef(null),
    };

    // Filter fields based on category and visibility
    const filteredMainFields = fieldConfigurations.filter(field =>
        field.category.categoryName === strings.categoryNameMain
    );

    const filteredAdditionalFields = fieldConfigurations.filter(field =>
        field.category.categoryName === strings.categoryNameAdditional
    );

    const getFieldValue = (fieldName: keyof SearchFilter): string | boolean | string[] | DataFlowType | DataFlowType[] => {
        return formFieldsState.searchFormFields[fieldName] || '';
    };

    const setFieldValue = (fieldName: string, value: string) => {
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [fieldName]: value
            }
        }));
    };

    const safeStringValue = (fieldName: keyof SearchFilter): string => {
        const value = getFieldValue(fieldName);

        if (Array.isArray(value)) {
            // Handle array case, check if it contains DataFlowType objects
            if (value.length > 0 && typeof value[0] === 'object' && 'value' in value[0]) {
                // Return the first 'value' from DataFlowType if it's an array of DataFlowType
                return (value[0] as DataFlowType).value || ''; // Ensure it always returns a string
            }
            return value.length > 0 ? String(value[0]) : ''; // Otherwise, assume it's an array of strings
        }

        if (typeof value === 'object' && value !== null && 'value' in value) {
            // Handle single DataFlowType object
            return (value as DataFlowType).value || ''; // Ensure it always returns a string
        }

        if (typeof value === 'boolean') {
            // Convert boolean to string
            return value ? 'true' : 'false';
        }

        // Fallback for strings or other types, default to empty string if undefined
        return value !== undefined ? String(value) : '';
    };

    useEffect(() => {
        const fetchFieldConfigurations = async () => {
            try {
                const url = `${backendSearchPublicUrl}/getFilters`;
                const response = await fetchWrapper(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data: VisibilityFilter[] = await response.json();
                const mappedData = data.map(field => ({
                    ...field,
                    fieldLabel: translateFilterKey(field.fieldLabel),
                }));


                setFieldConfigurations(mappedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchFieldConfigurations();
    }, [t]);

    useEffect(() => {
        const fetchSpecificFilters = async () => {
            try {
                const url = drupalIconsUrl
                const response = await fetchWrapper(url);
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const data: SpecificFilter[] = await response.json();

                setSpecificFilters(data);
            } catch (error) {
                console.error('Error fetching specific filters:', error);
            }
        }

        fetchSpecificFilters();
    }, []);

    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
            .react-datepicker-popper {
                z-index: 9999 !important;
            }
        `;
        // Append the style element to the document head
        document.head.appendChild(style);

        // Clean up the style element on component unmount
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSuggest = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setQuery(value);
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                freeText: value
            }
        }));
        if (value.length > 2) { // Start suggesting after 3 characters
            try {
                const url = `${backendSearchPublicUrl}/autocomplete?query=${value}`;
                const response = await fetchWrapper(url, { method: 'GET' });
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion: string) => {
        setQuery(suggestion); // Update query state with selected suggestion
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                freeText: suggestion // Update freeText in searchFormFields with selected suggestion
            }
        }));
        setSuggestions([]); // Clear suggestions after selection
    };

    const handleClickOutside = (event: MouseEvent) => {
        const container = containerRef.current;
        if (container && !container.contains(event.target as Node)) {
            setSuggestions([]); // Hide suggestions if click outside
        }
    };

    const createCustomSearchEvent = (name: string) => {
        const eventUUID = uuidGeneratorWithTimestamp();
        if (name === CustomEvents.SEARCH) {
            console.log(`Tracking event: ${name}, UUID: ${eventUUID}`);
            setEventUUID(eventUUID);
            return eventUUID;
        }
        console.log(`Tracking event: ${name}, UUID: ${eventUUID}`);
        appInsights.trackEvent(
            { name: name },
            { eventUUID: eventUUID }
        );
    };

    const createCustomFreeTextSearchEvent = (name: string, freeText: string) => {
        const terms = freeText.split(" ").filter(term => term.trim() !== "");

        terms.forEach(term => {
            const eventUUID = uuidGeneratorWithTimestamp();

            console.log(`Tracking event: ${name}, UUID: ${eventUUID}, term: ${term}`);

            appInsights.trackEvent(
                { name: name },
                { eventUUID: eventUUID, term: term }
            );
        });
    };

    const handleSearchClick = async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        // Clear suggestions when the search button is clicked
        setSuggestions([]);

        // Construct the search payload using values from formFieldsState
        const searchFields = {
            ...formFieldsState.searchFormFields // Use the latest form values
        };

        // Set searchFieldsState to match the current values from formFieldsState
        setSearchFieldsState({searchFormFields: formFieldsState.searchFormFields});
        setItemsPerPage(10);
        setResetItemsPerPage(true);

        try {
            const data = await searchForm(searchFields, sortBy, itemsPerPage, pageNumber);
            setSearchResult(data);

            createCustomSearchEvent(CustomEvents.SEARCH);

            if (searchFields.freeText) {
                createCustomFreeTextSearchEvent(CustomEvents.FREE_TEXT_SEARCH, searchFields.freeText);
            }

            if (searchFields.dateOrPeriodCoveredFrom || searchFields.dateOrPeriodCoveredTo) {
                createCustomSearchEvent(CustomEvents.DATE_COVERED)
            }

            if (searchFields.fisn) {
                createCustomSearchEvent(CustomEvents.FISN);
            }

            if (searchFields.isin) {
                createCustomSearchEvent(CustomEvents.ISIN);
            }

            if (searchFields.entityLEI) {
                createCustomSearchEvent(CustomEvents.ENTITY_LEI);
            }

            if (searchFields.entityName) {
                createCustomSearchEvent(CustomEvents.ENTITY_NAME);
            }

            if (searchFields.leiStatus && searchFields.leiStatus.length > 0) {
                createCustomSearchEvent(CustomEvents.ENTITY_LEI_STATUS);
            }

            if (searchFields.sizeOfEntity && (searchFields.sizeOfEntity.value !== "")) {
                createCustomSearchEvent(CustomEvents.ENTITY_SIZE);
            }

            if (searchFields.submissionDateFrom || searchFields.submissionDateTo) {
                createCustomSearchEvent(CustomEvents.SUBMISSION_DATE)
            }

            if (searchFields.typeOfInformation && searchFields.typeOfInformation.length > 0) {
                createCustomSearchEvent(CustomEvents.TYPE_OF_INFORMATION);
            }

            if (searchFields.collectionBodyName && searchFields.collectionBodyName.length > 0) {
                createCustomSearchEvent(CustomEvents.COLLECTION_BODY);
            }

            if (searchFields.countryOfRegisteredOffice && searchFields.countryOfRegisteredOffice.length > 0) {
                createCustomSearchEvent(CustomEvents.COUNTRY_OF_OFFICE);
            }

            if (searchFields.homeMemberStateOfEntity && searchFields.homeMemberStateOfEntity.length > 0) {
                createCustomSearchEvent(CustomEvents.HOME_MEMBER_STATE);
            }

            if (searchFields.hostMemberStateOfEntity && searchFields.hostMemberStateOfEntity.length > 0) {
                createCustomSearchEvent(CustomEvents.HOST_MEMBER_STATE);
            }

            if (searchFields.industrySector && searchFields.industrySector.length > 0) {
                createCustomSearchEvent(CustomEvents.INDUSTRY_SECTOR);
            }

            if (searchFields.legalFramework && searchFields.legalFramework.length > 0) {
                createCustomSearchEvent(CustomEvents.LEGAL_FRAMEWORK);
            }

            if (searchFields.originalLanguage && searchFields.originalLanguage.length > 0) {
                createCustomSearchEvent(CustomEvents.ORIGINAL_LANGUAGE);
            }

            if (searchFields.sizeOfSubmittingEntity && searchFields.sizeOfSubmittingEntity.length > 0) {
                createCustomSearchEvent(CustomEvents.SUBMITTING_ENTITY_SIZE);
            }

            if (searchFields.submittingEntityLEI) {
                createCustomSearchEvent(CustomEvents.SUBMITTING_ENTITY_LEI);
            }

            if (searchFields.submittingEntityName) {
                createCustomSearchEvent(CustomEvents.SUBMITTING_ENTITY_NAME);
            }

        } catch (error) {
            console.error('Search error:', error);
        } finally {
            setShowResults(true);
            setShowFilters(true);
            setResetItemsPerPage(false);
        }
    };

    const handleClearClick = () => {
        setShowResults(false);
        setShowFilters(false);
        setFormFieldsState({
            searchFormFields: {
                freeText: "",
                typeOfInformation: [],
                entityLEI: "",
                entityName: "",
                sizeOfEntity: { value: '' },
                submissionDateFrom: "",
                submissionDateTo: "",
                dateOrPeriodCoveredFrom: "",
                dateOrPeriodCoveredTo: "",
                submittingEntityName: "",
                submittingEntityLEI: "",
                sizeOfSubmittingEntity: [],
                leiStatus: [],
                countryOfRegisteredOffice: [],
                industrySector: [],
                originalLanguage: [],
                legalFramework: [],
                collectionBodyName: [],
                homeMemberStateOfEntity: [],
                hostMemberStateOfEntity: [],
                isin: "",
                fisn: "",
                voluntaryInfoFlag: false,
                historicalInfoFlag: false
            }
        });
        setQuery("")
        setSuggestions([]);
        Object.keys(multiSelectRefs.current).forEach((key) => {
            multiSelectRefs.current[key]?.clear();
        });
        Object.keys(singleSelectRefs.current).forEach((key) => {
            singleSelectRefs.current[key]?.clear();
        });
    }

    const handleInputChange = (e: CustomChangeEvent) => {
        const { name, value } = e.target;

        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [name]: value
            }
        }));
    };

    const getCheckboxValue = (value: string | boolean | string[] | DataFlowType | DataFlowType[]): boolean => {
        if (typeof value === 'boolean') {
            return value;
        }
        if (Array.isArray(value)) {
            // You can return true if the array has values or depending on how you handle multi-select checkboxes
            return value.length > 0;
        }
        if (typeof value === 'object' && 'value' in value) {
            // If it's a DataFlowType object, assume `value.value` holds the checkbox value
            return Boolean(value.value);
        }
        // Default case - return false if value doesn't match expected types
        return false;
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const {id, checked} = event.target;

        // Update state for formFieldsState
        setFormFieldsState(prevState => ({
            ...prevState,
            searchFormFields: {
                ...prevState.searchFormFields,
                [id]: checked
            }
        }));
    };

    const handleMultiSelectChange = (
        selectedOptions: OnChangeValue<TypeOption, true> | null,
        fieldName: string
    ) => {
        setFormFieldsState(prevState => {
            const newSearchFormFields = { ...prevState.searchFormFields };

            if (selectedOptions === null) {
                // Clear the selection if null
                newSearchFormFields[fieldName] = [];
            } else {
                const selectedValues = (selectedOptions as TypeOption[]).map(option => option.value);

                // If fieldName is typeOfInformation or sizeOfSubmittingEntity, handle separately
                if (fieldName === strings.typeOfInformation || fieldName === strings.sizeOfSubmittingEntity) {
                    const selectedDataFlowValues: DataFlowType[] = (selectedOptions as TypeOption[]).map(option => ({
                        value: option.value
                    }));
                    newSearchFormFields[fieldName] = selectedDataFlowValues;
                } else {
                    // For other fields, store as an array of strings
                    newSearchFormFields[fieldName] = selectedValues;
                }
            }

            return {
                ...prevState,
                searchFormFields: newSearchFormFields
            };
        });
    };

    const renderTooltip = (message: string) => (
        <Tooltip id="button-tooltip">{message}</Tooltip>
    );

    const handleRemoveFilter = (key: keyof SearchFilter) => {
        setFormFieldsState(prevState => {
            // Create a new filter state with the specified key updated
            const updatedFilters = { ...prevState.searchFormFields };

            // Special handling for multi-selection fields
            if (
                key === strings.typeOfInformation ||
                key === strings.sizeOfSubmittingEntity ||
                key === strings.leiStatus ||
                key === strings.countryOfRegisteredOffice ||
                key === strings.industrySector ||
                key === strings.originalLanguage ||
                key === strings.legalFramework ||
                key === strings.collectionBodyName ||
                key === strings.homeMemberStateOfEntity ||
                key === strings.hostMemberStateOfEntity
            ) {
                updatedFilters[key] = []; // Clear the array for multi-selection filters

                multiSelectRefs.current[key]?.clear();
            } else if (key === strings.freeText) {
                setQuery("");
                updatedFilters[key] = ""; // Ensure the key is set to an empty string
            } else if (key === strings.sizeOfEntity) {
                updatedFilters[key] = { value: "" };
                singleSelectRefs.current[key]?.clear();
            } else {
                updatedFilters[key] = ""; // Set the filter value to an empty string
            }

            // Return the updated state
            return {
                ...prevState,
                searchFormFields: updatedFilters
            };
        });

        // Additional logic to ensure alerts are removed from UI
        setSearchFieldsState(prevState => ({
            searchFormFields: {
                ...prevState.searchFormFields,
                [key]: key === strings.sizeOfEntity ? { value: "" } : ""
            }
        }));
    };

    const handleDateChange = (date: Date | null, fieldName: string) => {
        // Format the date as YYYY-MM-DD if date is valid, or set to empty if null
        const formattedDate = date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0] : '';

        if (fieldName === strings.submissionDateFrom) {
            if (date && formFieldsState.searchFormFields.submissionDateTo) {
                const submissionToDate = new Date(formFieldsState.searchFormFields.submissionDateTo);
                if (date > submissionToDate) {
                    return;
                }
            }
            setFieldValue(strings.submissionDateFrom, formattedDate);
            if (date && formFieldsState.searchFormFields.submissionDateTo) {
                const submissionToDate = new Date(formFieldsState.searchFormFields.submissionDateTo);
                if (submissionToDate < date) {
                    setFieldValue(strings.submissionDateTo, '');
                }
            }
        } else if (fieldName === strings.submissionDateTo) {
            if (date && formFieldsState.searchFormFields.submissionDateFrom) {
                const submissionFromDate = new Date(formFieldsState.searchFormFields.submissionDateFrom);
                if (date < submissionFromDate) {
                    return;
                }
            }
            setFieldValue(strings.submissionDateTo, formattedDate);
        } else if (fieldName === strings.dateOrPeriodCoveredFrom) {
            setFieldValue(strings.dateOrPeriodCoveredFrom, formattedDate);
            if (date && formFieldsState.searchFormFields.dateOrPeriodCoveredTo) {
                const dateOrPeriodCoveredTo = new Date(formFieldsState.searchFormFields.dateOrPeriodCoveredTo);
                if (dateOrPeriodCoveredTo < date) {
                    setFieldValue(strings.dateOrPeriodCoveredTo, '');
                }
            }
        } else if (fieldName === strings.dateOrPeriodCoveredTo) {
            if (date && formFieldsState.searchFormFields.dateOrPeriodCoveredFrom) {
                const dateOrPeriodCoveredFrom = new Date(formFieldsState.searchFormFields.dateOrPeriodCoveredFrom);
                if (date < dateOrPeriodCoveredFrom) {
                    return;
                }
            }
            setFieldValue(strings.dateOrPeriodCoveredTo, formattedDate);
        }

        // If the date is cleared (null), reset the respective date fields
        if (!date) {
            setFieldValue(fieldName, '');  // Clear the date field
        }
    };

    const getOptionsForField = useCallback((fieldName: string): TypeOption[] => {
        // Transform fieldConfigurations into the required format
        const visibilityFilter = fieldConfigurations.find(visibilityFilter => visibilityFilter.fieldName === fieldName);
        return visibilityFilter && visibilityFilter.options ? visibilityFilter.options.map(option => ({
            value: option.value,
            label: option.label
        })) : [];
    }, [fieldConfigurations]);

    const getLabelForValue = (fieldName: string, value: string): string => {
        const options = getOptionsForField(fieldName);
        const option = options.find(option => option.value === value);
        return option ? option.label : value; // Return the label if found, otherwise return the raw value
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Stack>
            <div className="Intro py-4 py-lg-5">
                <Container>
                    <Row>
                        <Col>
                            <h1 className="fs-4 text-center">
                                European Single Access Point
                                <br/>
                                <small>
                                    {t(SEARCH_HEADER_TEXT)}
                                </small>
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Form className="bg-lighter py-5">
                <Container ref={containerRef}>
                    <Row>
                        <Col xs={12}>
                            <>
                                {showAlert && (
                                    <Alert variant="info" className="alert alert-info mt-1 mb-5 alert-dismissible fade show" onClose={handleCloseAlert} dismissible>
                                        <strong>{t(IMPORTANT_ANNOUNCEMENTS_LABEL)}</strong> {t(IMPORTANT_ANNOUNCEMENTS_TEXT)}
                                    </Alert>
                                )}
                            </>
                            <FreeTextInput
                                query={query}
                                handleSuggest={handleSuggest}
                                handleSuggestionClick={handleSuggestionClick}
                                suggestions={suggestions}
                                renderTooltip={renderTooltip}
                            />
                        </Col>
                    </Row>

                    {showFilters && (
                        <AppliedFilters
                            showFilters={showFilters}
                            searchFieldsState={searchFieldsState}
                            handleRemoveFilter={handleRemoveFilter}
                            getLabelForValue={getLabelForValue}
                        />
                    )}

                    <Row>
                        <>
                            <Col>
                                <Button
                                    variant="link"
                                    disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                    onClick={() => setOpenSearchFilters(!openSearchFilters)}
                                    className={`ExpColl ms-1 ${openSearchFilters ? '' : 'collapsed'}`}
                                    aria-expanded={openSearchFilters}
                                    aria-controls="SearchFilters"
                                >
                                    {t(SEARCH_FILTERS_TEXT)}
                                </Button>
                            </Col>

                            <Collapse in={openSearchFilters}>
                                <Col xs={12} className="py-3" id="SearchFilters">
                                    <Row>
                                        <SearchField
                                            fields={filteredMainFields}
                                            formFieldsState={formFieldsState}
                                            handleInputChange={handleInputChange}
                                            handleMultiSelectChange={handleMultiSelectChange}
                                            handleDateChange={handleDateChange}
                                            datePickers={datePickers}
                                            getOptionsForField={getOptionsForField}
                                            safeStringValue={safeStringValue}
                                            renderTooltip={renderTooltip}
                                            handleCheckboxChange={handleCheckboxChange}
                                            getCheckboxValue={getCheckboxValue}
                                            setFormFieldsState={setFormFieldsState}
                                            multiSelectRefs = {multiSelectRefs}
                                            singleSelectRefs = {singleSelectRefs}
                                        />
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="link"
                                                className={`ExpColl ms-1 ${openAdditionalFilters ? '' : 'collapsed'}`}
                                                onClick={() => setOpenAdditionalFilters(!openAdditionalFilters)}
                                                aria-expanded={openAdditionalFilters}
                                                aria-controls="SearchAdditionalFilters"
                                            >
                                                {t(SEARCH_MORE_FILTERS_TEXT)}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Collapse in={openAdditionalFilters}>
                                        <Row id="SearchAdditionalFilters" className="py-3">
                                            <Col xs={12} className="mb-3">
                                                <Row>
                                                    <SearchField
                                                        fields={filteredAdditionalFields}
                                                        formFieldsState={formFieldsState}
                                                        handleInputChange={handleInputChange}
                                                        handleMultiSelectChange={handleMultiSelectChange}
                                                        handleDateChange={handleDateChange}
                                                        datePickers={datePickers}
                                                        getOptionsForField={getOptionsForField}
                                                        safeStringValue={safeStringValue}
                                                        renderTooltip={renderTooltip}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                        getCheckboxValue={getCheckboxValue}
                                                        setFormFieldsState={setFormFieldsState}
                                                        multiSelectRefs = {multiSelectRefs}
                                                        singleSelectRefs = {singleSelectRefs}
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </Col>
                            </Collapse>
                        </>

                        <Col className="d-flex justify-content-end mt-5 mt-lg-0">
                            <Button
                                type="submit"
                                disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                variant="primary"
                                onClick={handleSearchClick}
                                className="me-2"
                            >
                                <i className="bi bi-search me-2"></i> {t(SEARCH_BUTTON_TEXT)}
                            </Button>
                            <Button
                                type="reset"
                                disabled={!hasRoles([Roles.SEARCH_ENGINE_ROLE])}
                                variant="link"
                                className="link-dark ms-3"
                                onClick={handleClearClick}
                            >
                                <i className="bi bi-arrow-clockwise me-2"></i> {t(SEARCH_CLEAR_BUTTON_TEXT)}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
            {showResults && (
                searchResult.results.length > 0 ? (
                    <SearchResults
                        initialSearchResults={searchResult}
                        searchFields={searchFieldsState.searchFormFields}
                        resetItemsPerPage={resetItemsPerPage}
                        cartLimit={cartLimit}
                        searchEventUUID={eventUUID}
                        specificFilters={specificFilters}
                    />
                ) : (
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} lg={6} className="mx-auto">
                                <div className="text-center">{t(SEARCH_NO_RESULTS)}</div>
                            </Col>
                        </Row>
                    </Container>
                )
            )}
        </Stack>
    )
}

export default Search;
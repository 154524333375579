import { CartItem } from "../../model/CartItem";
import { cartValidation } from "../DatasetDetailsService/DatasetDetailsService";
import { CartValidationResult } from "../../model/CartValidations";


export const validateCurrentCart = async (cartDatasets: CartItem[]): Promise<CartValidationResult> => {

  if (cartDatasets.length === 0) {
    return {
      isValid: true,
      showAlert: false,
      message: "",
      error: false,
      unavailableDatasets: new Set(),
    };
  }

  const datasetIds = cartDatasets.map((item) => item.datasetId);
  const validations = await cartValidation(datasetIds);

  if (!validations) {
    return {
      isValid: false,
      showAlert: false,
      message:
        "Something went wrong while validating your cart. Please try refreshing the page.",
      error: true,
      unavailableDatasets: new Set(),
    };
  }

  const {datasetsNotAvailable,numberOfDatasets,numberLimit,sizeOfDatasets,sizeLimit,} = validations;
  const setOfNotAvailableDatasets: Set<string> = datasetsNotAvailable ? new Set(datasetsNotAvailable) : new Set();

  if (numberOfDatasets > numberLimit) {
    //number check
    return {
      isValid: false,
      showAlert: true,
      message: `The number of datasets added to the cart exceeds the allowed limit of ${numberLimit}.`,
      error: false,
      unavailableDatasets: setOfNotAvailableDatasets,
    };
  } else if (setOfNotAvailableDatasets.size > 0) {
    //availability check
    console.log("DATASETS NOT",setOfNotAvailableDatasets);
    return {
      isValid: false,
      showAlert: true,
      message:
      "Grayed-out datasets are unavailable. Please remove them .",
      error: false,
      unavailableDatasets: setOfNotAvailableDatasets,
    };
  } else if (sizeOfDatasets > sizeLimit) {
    //size check
    return {
      isValid: false,
      showAlert: true,
      message: `The volume of datasets added to the cart exceeds the allowed limit of ${sizeLimit} MB.`,
      error: false,
      unavailableDatasets: setOfNotAvailableDatasets,
    };
  } else {
    return {
      isValid: true,
      showAlert: false,
      message: "",
      error: false,
      unavailableDatasets: setOfNotAvailableDatasets,
    };
  }
};
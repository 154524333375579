/* eslint-disable @typescript-eslint/no-explicit-any */

import { useUserInfo } from "@/hooks/useUserInfo";
import { useCallback, useEffect, useRef, useState } from "react";
import { UserInfo } from "../../model/UserInfo.ts";
import { MONITORING_DATA_QUALITY_HISTORICAL_CURRENT_REPORT_IDENTIFIER, MONITORING_DATA_QUALITY_HISTORICAL_DATE_OF_REPORT, MONITORING_DATA_QUALITY_HISTORICAL_FETCH_REPORT_FAILED_MESSAGE, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATASET_ID, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATE_TIME, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ERROR_MSG, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_FILETYPE_CODE, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PRIORITY, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PUBLICATION_STATUS, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_SEAL_VALIDATION_STATUS, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_STATUS, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_1, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_2, MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_3, MONITORING_DATA_QUALITY_HISTORICAL_TITLE, Roles } from "@/constants/EsapConstants.ts";
import { HistoricalReportResponse } from "@/model/MonitoringResults.ts";
import { fetchLatestHistoricalReport, fetchSpecificHistoricalReport, getQualityIssuesOfDataset } from "@/services/MonitoringService/MonitoringService.ts";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ExpandableTable } from "./common/ExpandableTable.tsx";
import { createDatasetRowsFromHistoricalReportResponse, formatDate, TableDatasetRow, } from "./common/ExpandableTableData.ts";
import { t } from "i18next";
import { PaginationState } from "@tanstack/react-table";

const QualityMonitoringHistorical: React.FC = () => {
    // Reports
    const [historicalReportsResponse, setHistoricalReportsResponse] = useState<HistoricalReportResponse | null>(null);
    const [waitHistoricalReportsResponse, setWaitHistoricalReportsResponse] = useState<boolean>(true);
    const [tableData, setTableData] = useState<TableDatasetRow[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [reportIdSelected, setReportIdSelected] = useState<number>(0);
    // Common error message
    const [error, setError] = useState<string | null>(null);
    // User Info
    const {user} = useUserInfo();
    const [userInfo, setUserInfo] = useState<UserInfo | null>();
    // Used to have expanded only one row each time
    const expandedRowIndex = useRef("");
    const maxHistoricalReportId = useRef(0);

    useEffect(() => {
        setUserInfo(user);
    }, [user, setUserInfo]);

    const hasRoles = useCallback((roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    }, [userInfo]);

    useEffect(() => {
        const getHistoricalReport = async () => {
            let errorMsg = t(MONITORING_DATA_QUALITY_HISTORICAL_FETCH_REPORT_FAILED_MESSAGE);
            try {
                let response: HistoricalReportResponse | null;
                if (reportIdSelected !== 0) {
                    response = await fetchSpecificHistoricalReport(pagination, reportIdSelected.toString());
                } else {
                    response = await fetchLatestHistoricalReport(pagination);
                }
                setWaitHistoricalReportsResponse(false);
                if (!response) {
                    setError(errorMsg);
                } else {
                    setHistoricalReportsResponse(response);
                    setReportIdSelected(Number(response.reportId));
                    if (maxHistoricalReportId.current === 0) {
                        maxHistoricalReportId.current = Number(response.reportId);
                    }
                    setTableData(createDatasetRowsFromHistoricalReportResponse(response));
                }
            } catch (error) {
                if (error instanceof Error) {
                    errorMsg = error.message;
                }
                setError(errorMsg);
                console.error(errorMsg);
            }
        };

        if (hasRoles([Roles.ESAP_DATA_MANAGER_ROLE])) {
            setWaitHistoricalReportsResponse(true);
            getHistoricalReport();
        }
    }, [hasRoles, pagination, reportIdSelected]);

    function handlePageChange(action: string) {
        let pageIndex = pagination.pageIndex;
        const totalPages = Math.ceil((historicalReportsResponse?.totalResults ? historicalReportsResponse?.totalResults : 0) / pagination.pageSize);

        switch (action) {
        case "next":
            if (Number(reportIdSelected ? reportIdSelected : 0) < Number(maxHistoricalReportId.current)) {
                pageIndex += 1;
            }
            break;
        case "previous":
            if (pagination.pageIndex > 1) {
                pageIndex -= 1;
            }
            break;
        case "first":
            pageIndex = 1;
            break;
        case "last":
            pageIndex = totalPages;
            break;
        default:
            return;
        }

        if (pageIndex != pagination.pageIndex) {
            setPagination({pageIndex: pageIndex, pageSize: pagination.pageSize});
        }
    }

    function handlePageSizeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const newPageSize = parseInt(event.target.value, 10);
        if (newPageSize != pagination.pageSize) {
            setPagination({pageIndex: 1, pageSize: newPageSize});
        }
    }

    function handleReportChange(action: string) {
        switch (action) {
            case "next":
                if (reportIdSelected < maxHistoricalReportId.current) {
                    setReportIdSelected(reportIdSelected+1);
                }
                break;
            case "previous":
                if (reportIdSelected > 1) {
                    setReportIdSelected(reportIdSelected-1);
                }
                break;
            case "first":
                setReportIdSelected(1);
                break;
            case "last":
                setReportIdSelected(maxHistoricalReportId.current);
                break;
            default:
                return;
        }
    }

    const [expandedRowState, setExpandedRowState] = useState<any>([]);

    const ExpandableColumns = () => { 
        return [
            {
                accessorKey: "datasetId",
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATASET_ID),
                cell: ({ row, getValue }: any) => (
                <div
                    style={{
                        paddingLeft: `${row.depth * 2}rem`,
                    }}
                >
                    <button
                        {...{
                            onClick: () => {
                                row.getToggleExpandedHandler()();
                                expandedRowIndex.current = row.id;
                                if (!row.getIsExpanded()) {
                                    setExpandedRowState({ waitResponse: true });
                                    getQualityIssuesOfDataset(historicalReportsResponse?.reportId, getValue(), false).then((qualityIssuesTable) => {
                                        setExpandedRowState(qualityIssuesTable);
                                    });
                                }
                            }
                        }}
                    >
                        {row.getIsExpanded()
                            ? <a className="btn btn-link ExpColl">{getValue()}</a>
                            : <a className="btn btn-link ExpColl collapsed">{getValue()}</a>}
                    </button>
                </div>
                )
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_FILETYPE_CODE),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_DATE_TIME),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ERROR_MSG),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PUBLICATION_STATUS),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_SEAL_VALIDATION_STATUS),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_ACKNOWLEDGEMENT_STATUS),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_PRIORITY),
            },
            {
                header: t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_HEADER_STATUS),
            }
        ];
    }


    if (error) {
        return (
        <>
            <HistoricalQualityMonitoringHeader/>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "red",
                    textAlign: "center",
                }}
            >
                {error}
            </div>
        </>
        );
    }
    
    if (waitHistoricalReportsResponse) {
        return (
        <>
            <HistoricalQualityMonitoringHeader/>
            <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </>
        )
    }

    if (!waitHistoricalReportsResponse && historicalReportsResponse) {
        return (
        <> 
            <HistoricalQualityMonitoringHeader/>

            <div className="py-5">
            <Container>
                <Row className="py-3">
                <Col>
                    {/* SELECT REPORT ID */}
                    <div className="my-2">
                        <span className="fw-normal mb-3 fs-5">{t(MONITORING_DATA_QUALITY_HISTORICAL_CURRENT_REPORT_IDENTIFIER)}:</span>

                        <button
                            className="border rounded p-1"
                            style={{
                                marginLeft: "0.5rem",
                            }}
                            onClick={() => handleReportChange("first")}
                            disabled={reportIdSelected === 1}
                        >
                            {'<<'}
                        </button>

                        <button
                            className="mx-2 border rounded p-1"
                            onClick={() => handleReportChange("previous")}
                            disabled={reportIdSelected === 1}
                        >
                            {'<'}
                        </button>
                    
                        {reportIdSelected}
                    
                        <button
                            className="mx-2 border rounded p-1"
                            onClick={() => handleReportChange("next")}
                            disabled={reportIdSelected === maxHistoricalReportId.current}
                        >
                            {'>'}
                        </button>

                        <button
                            className="border rounded p-1"
                            style={{
                                marginLeft: "0.5rem",
                            }}
                            onClick={() => handleReportChange("last")}
                            disabled={reportIdSelected === maxHistoricalReportId.current}
                        >
                            {'>>'}
                        </button>
                    </div>

                    <div className="my-2">
                        <span className="fw-normal mb-3 fs-5">{t(MONITORING_DATA_QUALITY_HISTORICAL_DATE_OF_REPORT)}: </span>{historicalReportsResponse.reportTimestamp ? formatDate(new Date(historicalReportsResponse.reportTimestamp)) : ""}

                    </div>

                    {/* TABLE - RESULTS */}
                    <ExpandableTable
                        data={tableData}
                        columns={ExpandableColumns()}
                        expandedRowContent={expandedRowState}
                        expandedRowIndex={expandedRowIndex.current}
                        reportId = ""
                        isLatest = {false}
                    />

                    {/* PAGINATION */}
                    <div className="d-flex align-items-center justify-content-end gap-2 pt-2">
                        <button
                            className="border rounded p-1"
                            onClick={() => handlePageChange("first")}
                            disabled={pagination.pageIndex === 1}
                        >
                            {'<<'}
                        </button>

                        <button
                            className="border rounded p-1"
                            onClick={() => handlePageChange("previous")}
                            disabled={pagination.pageIndex === 1}
                        >
                            {'<'}
                        </button>

                        <button
                            className="border rounded p-1"
                            onClick={() => handlePageChange("next")}
                            disabled={pagination.pageIndex * pagination.pageSize >= historicalReportsResponse.totalResults}
                        >
                            {'>'}
                        </button>

                        <button
                            className="border rounded p-1"
                            onClick={() => handlePageChange("last")}
                            disabled={pagination.pageIndex * pagination.pageSize >= historicalReportsResponse.totalResults}
                        >
                            {'>>'}
                        </button>

                        <span className="d-flex align-items-center gap-1">
                            <div>{t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_1)}</div>
                            <strong>
                            {pagination.pageIndex} {t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_2)}{' '} {Math.floor(historicalReportsResponse.totalResults / pagination.pageSize) + 1}
                            </strong>
                        </span>
                
                        <select style={{width: "auto"}} className="form-select form-select-sm"
                            value={pagination.pageSize}
                            onChange={handlePageSizeChange}
                        >
                            {[10, 20, 40].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {t(MONITORING_DATA_QUALITY_HISTORICAL_TABLE_PAGINATION_TEXT_3)} {pageSize}
                            </option>
                            ))}
                        </select>
                    </div>
                
                </Col>
                </Row>
            </Container>
            </div>
        </>
        )
    }
}


//  HEADER
const HistoricalQualityMonitoringHeader = () => {
    return (
        <>
        <div className="Intro py-2 py-lg-3">
            <Container>
                <Row>
                    <Col>
                        <h1 className="fs-4 text-center">
                            {t(MONITORING_DATA_QUALITY_HISTORICAL_TITLE)}
                        </h1>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}

export default QualityMonitoringHistorical;
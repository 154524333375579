import React, { useEffect, useState } from 'react';
import { fetchFilterItems } from '@/services/HistoryOfSubmission/HistoryOfSubmissionService.ts';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HS_FILTER_HISTORY_OF_SUBMISSIONS, HS_APPLY_FILTERS, HS_RESET_FILTERS } from "../../constants/EsapConstants.ts";
import { useTranslation } from "react-i18next";
import { Fieldset, FormProps } from "./types.tsx";
import { MultiSelect } from "@/components/ui/multi-select.tsx";

const Form: React.FC<FormProps> = ({ fieldsets }) => {
    const { t } = useTranslation();
    const [dateRanges, setDateRanges] = useState<{ [key: string]: { from: Date | null; to: Date | null } }>({});
    const [formData, setFormData] = useState<FormData | object>({});

    const handleMultiSelectChange = (fieldsetName: string, value: string[]) => {
        setFormData((prevData) => ({ ...prevData, [fieldsetName]: value }));
    };

    const handleDateChange = (date: Date | null, fieldName: string, type: 'from' | 'to') => {
        setDateRanges((prev) => ({
            ...prev,
            [fieldName]: {
                ...prev[fieldName],
                [type]: date,
            },
        }));
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    return (
        <form>
            <h2 className="fs-5 fw-semibold mb-3 pb-3 border-bottom">{t(HS_FILTER_HISTORY_OF_SUBMISSIONS)}</h2>

            {fieldsets.map((fieldset, index) => (

                <fieldset key={index} className="mb-3 pb-3 border-bottom">
                    <legend className="fw-semibold">
                        {fieldset.fieldsetName}
                        {fieldset.type === "checkbox" &&
                        <button
                            className="btn btn-sm FormHelp"
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-title={`More information about ${fieldset.fieldsetName}`}
                        >
                            <i className="bi bi-info-lg" aria-hidden="true"></i>
                            <span className="visually-hidden">Help with this fieldset</span>
                        </button>
                        }
                    </legend>

                    {(fieldset.type === "checkbox")  &&
                        fieldset.fields.map((field, idx) => (
                            <div key={idx} className="form-check mb-2">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`${fieldset.fieldsetName}-${idx}`}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`${fieldset.fieldsetName}-${idx}`}
                                >
                                    {field.label}
                                </label>
                            </div>
                        ))}

                    {fieldset.type === 'date' && (
                        <div className="input-group">
                            {fieldset.fields.map((field, idx) => {
                                const currentRange = dateRanges[fieldset.fieldsetId] || { from: null, to: null };

                                return (
                                    <React.Fragment key={idx}>
                                        <input
                                            aria-describedby={`${fieldset.fieldsetName}-Help`}
                                            type="text"
                                            className="form-control"
                                            placeholder={field.label}
                                            value={
                                                field.label === 'From'
                                                    ? currentRange.from
                                                        ? currentRange.from.toLocaleDateString()
                                                        : ''
                                                    : currentRange.to
                                                        ? currentRange.to.toLocaleDateString()
                                                        : ''
                                            }
                                            readOnly
                                            onClick={() => {
                                                if (field.label === 'From') {
                                                    document.getElementById(`${fieldset.fieldsetId}-From-Picker`)?.click();
                                                } else {
                                                    document.getElementById(`${fieldset.fieldsetId}-To-Picker`)?.click();
                                                }
                                            }}
                                        />
                                        <button
                                            className="btn btn-sm btn-link link-dark bg-white border border-start-0"
                                            type="button"
                                            onClick={() => {
                                                if (field.label === 'From') {
                                                    document.getElementById(`${fieldset.fieldsetId}-From-Picker`)?.click();
                                                } else {
                                                    document.getElementById(`${fieldset.fieldsetId}-To-Picker`)?.click();
                                                }
                                            }}
                                        >
                                            <i className="bi bi-calendar4" aria-hidden="true"></i>
                                            <span className="visually-hidden">Date picker</span>
                                        </button>
                                        {field.label === 'From' && (
                                            <DatePicker
                                                id={`${fieldset.fieldsetId}-From-Picker`}
                                                selected={currentRange.from}
                                                onChange={(date) => handleDateChange(date, fieldset.fieldsetId, 'from')}
                                                selectsStart
                                                className="d-none"
                                            />
                                        )}
                                        {field.label === 'To' && (
                                            <DatePicker
                                                id={`${fieldset.fieldsetId}-To-Picker`}
                                                selected={currentRange.to}
                                                onChange={(date) => handleDateChange(date, fieldset.fieldsetId, 'to')}
                                                selectsEnd
                                                className="d-none"
                                            />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}

                    {fieldset.type === "input" && (
                        <div className="FormComponent mb-4 ">
                            <div className="input-group">
                                <button
                                    id={`${fieldset.fieldsetId}-Help`}
                                    className="btn btn-sm border-end FormHelp"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-title={`More information about ${fieldset.fieldsetName}`}
                                >
                                    <i className="bi bi-info-lg" aria-hidden="true"></i>
                                    <span className="visually-hidden">Help with this field</span>
                                </button>
                                <input
                                    aria-describedby={`${fieldset.fieldsetId}-Help`}
                                    id={`${fieldset.fieldsetId}-input`}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                    )}

                    {fieldset.type === "dropdown" && (
                        <div className="FormComponent mb-4  ">
                            <div className="input-group">
                                <button
                                    id={`${fieldset.fieldsetId}-Help`}
                                    className="btn btn-sm border-end FormHelp"
                                    type="button"
                                    data-bs-toggle="tooltip"
                                    data-bs-title={`More information about ${fieldset.fieldsetName}`}>
                                    <i className="bi bi-info-lg" aria-hidden="true"></i>
                                    <span className="visually-hidden">Help with this field</span>
                                </button>
                                <MultiSelect
                                    options={fieldset.fields}
                                    // value={formData[fieldset.fieldsetName] || []}
                                    onValueChange={(selected) =>
                                        handleMultiSelectChange(fieldset.fieldsetId, selected)
                                    }
                                    placeholder={`Select ${fieldset.fieldsetName}`}
                                />
                            </div>
                        </div>
                    )}
                </fieldset>
            ))}

            <div className="d-flex justify-content-between">
                <button type="submit" className="btn btn-primary">
                    <i className="bi bi-filter me-2"></i> {t(HS_APPLY_FILTERS)}
                </button>
                <button type="reset" className="btn btn-link link-dark ms-3">
                    <i className="bi bi-arrow-clockwise me-2"></i> {t(HS_RESET_FILTERS)}
                </button>
            </div>
        </form>
    );
};

const HistoryFiltersPopulate: React.FC = () => {
    const [fieldsets, setFieldsets] = useState<Fieldset[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchFilterItems();
                setFieldsets(data);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch fieldsets:', err);
                setError('Failed to load filter options.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error:{error}</div>;
    }

    return (
        <div className="col-lg-4 px-3 px-lg-4 py-5 bg-lighter">
            <Form fieldsets={fieldsets} />
        </div>
    );
};

export default HistoryFiltersPopulate;

import {useState, useRef, FormEvent} from 'react';
import {
    HS_FILE_RE_SUBMISSION, HS_HELP_WITH_THIS_FILE, HS_LIST_OF_FILES_TO_BE_REPROCESSED,
    HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED, HS_REPROCESS_FILES
} from '../../constants/EsapConstants.ts';
import {useTranslation} from 'react-i18next';
import {Message, UploadFile} from '../Common/UploadFile.tsx';
import {Alert, Form, Row} from 'react-bootstrap';
import {uploadFileToServer} from '../../services/ReportingEntitiesService/ReportingEntitiesService.ts';


const HistoryOfSubmission = () => {
    const {t} = useTranslation();


    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileChange = (file: File | null) => {
        setSelectedFile(file);
    };
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'danger' | ''>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);

    const handleMessageChange = (newMessage: Message) => {
        setMessage(newMessage.message);
        setMessageType(newMessage.type);
    };

    const handleUpload = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (!selectedFile) {
            setMessage('Please fill in required fields.');
            setMessageType('danger');
            return;
        }

        event.preventDefault();
        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const result = await uploadFileToServer(formData, 'SUBMISSION');
            setMessage(result.message);
            setMessageType(result.success ? 'success' : 'danger');
            setSelectedFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            setMessage('An unexpected error occurred.');
            setMessageType('danger');
        } finally {
            setSelectedFile(null);
            setIsUploading(false);
            setValidated(false);
        }
    };

    return (
        <main>
            <div className="Intro py-2 py-lg-3">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1 className="fs-4 text-center">
                                {t(HS_FILE_RE_SUBMISSION)}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <Form noValidate validated={validated} onSubmit={handleUpload} className="bg-lighter py-2 py-lg-3">
                <div className="container">
                    <div className="row py-3">

                        <div className="col-lg-6">
                            <div className="FormComponent mb-3">
                                <UploadFile onFileChange={handleFileChange} onMessageChange={handleMessageChange}
                                            isUploading={isUploading} showButton={false}
                                            ref={fileInputRef}
                                            labelMessage={t(HS_LIST_OF_FILES_TO_BE_REPROCESSED)}>

                                </UploadFile>
                                <Row>
                                    {message && (
                                        <Alert variant={messageType} className="mt-3">
                                            {message}
                                        </Alert>
                                    )}
                                </Row>
                            </div>
                        </div>

                        <div className="col-lg-6">

                                <div className="FormComponent mb-3">

                                    <label htmlFor="FRPath" className="form-label">{t(HS_PATH_WHERE_THE_FILES_TO_BE_REPROCESSED_ARE_LOCATED)}:</label>
                                    <div className="input-group">
                                        <button id="FRPathHelp" className="btn btn-sm border-end FormHelp" type="button"
                                                data-bs-toggle="tooltip"
                                                data-bs-title="More information about this field">
                                            <i className="bi bi-info-lg" aria-hidden="true"></i><span
                                            className="visually-hidden">{t(HS_HELP_WITH_THIS_FILE)}</span></button>
                                        <input aria-describedby="FRPathHelp" id="FRPath" type="text"
                                               className="form-control"/>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-12 d-flex align-items-center pt-1 justify-content-end">
                                <button type="button" className="btn btn-primary mb-3"><i
                                    className="bi bi-arrow-repeat me-2"></i> {t(HS_REPROCESS_FILES)}
                                </button>
                            </div>

                        </div>
                    </div>
            </Form>


        </main>
    );
};

export default HistoryOfSubmission;

import React, {useState} from 'react';
import { Col, Row, Form, Button, InputGroup, OverlayTrigger } from 'react-bootstrap';
import { OnChangeValue } from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TypeOption } from "@/model/TypeOption.ts";
import { SearchState } from "../Search/Search.tsx";
import { SearchFilter } from "@/model/SearchFilter.ts";
import { VisibilityFilter } from "@/model/VisibilityFilter.ts";
import { useTranslation } from "react-i18next";
import {
    SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER,
    SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER
} from "@/constants/EsapConstants.ts";
import { DataFlowType } from '@/model/DataFlowType.ts';
import strings from "../../loc/strings.ts";
import {MultiSelect, MultiSelectRef} from "@/components/ui/multi-select.tsx";
import {Select, SingleSelectRef} from "@/components/ui/select.tsx";

interface FieldGroupProps {
    fields: VisibilityFilter[];
    formFieldsState: SearchState;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    handleMultiSelectChange: (selectedOptions: OnChangeValue<TypeOption, true> | null, fieldName: string) => void; // Accept null
    handleDateChange: (date: Date | null, fieldName: string) => void;
    datePickers: { [key: string]: React.RefObject<DatePicker> };
    getOptionsForField: (fieldName: string) => TypeOption[];
    safeStringValue: (fieldName: keyof SearchFilter) => string;
    renderTooltip: (text: string) => JSX.Element;
    handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    getCheckboxValue: (value: boolean | string | string[] | DataFlowType | DataFlowType[]) => boolean;
    setFormFieldsState: React.Dispatch<React.SetStateAction<SearchState>>;
    multiSelectRefs: React.MutableRefObject<{ [key: string]: MultiSelectRef | null }>;
    singleSelectRefs: React.MutableRefObject<{ [key: string]: SingleSelectRef | null }>;
}

const SearchField: React.FC<FieldGroupProps> = ({
                                                    fields,
                                                    formFieldsState,
                                                    handleInputChange,
                                                    handleDateChange,
                                                    datePickers,
                                                    getOptionsForField,
                                                    safeStringValue,
                                                    renderTooltip,
                                                    handleCheckboxChange,
                                                    getCheckboxValue,
                                                    setFormFieldsState,
                                                    multiSelectRefs,
                                                    singleSelectRefs
                                                }) => {
    const { t } = useTranslation();
    const [inRow, setInRow] = useState<boolean>(false);

    const renderField = (field: VisibilityFilter, fieldName: string) => {
        switch (field.fieldType) {
            case 'dropdown':
                switch (field.isMulti) {
                    case true:
                        return (
                            <MultiSelect
                                aria-label={t(field.fieldLabel)}
                                options={getOptionsForField(field.fieldName).map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                }))}
                                ref={(fieldset) => (multiSelectRefs.current[fieldName] = fieldset)}
                                onValueChange={(newValue: string[]) => {
                                    // Check if fieldName is one of the specified fields
                                    if ([strings.sizeOfSubmittingEntity, strings.typeOfInformation].includes(field.fieldName)) {
                                        // Convert selected options to DataFlowType[]
                                        const dataFlowSelections: DataFlowType[] = newValue.map((value) => ({
                                            value
                                        }));
                                        // Update formFieldsState with the new dataFlowSelections
                                        setFormFieldsState((prevState) => ({
                                            ...prevState,
                                            searchFormFields: {
                                                ...prevState.searchFormFields,
                                                [field.fieldName]: dataFlowSelections,
                                            },
                                        }));
                                    } else {
                                        // Handle update for other fields as needed
                                        setFormFieldsState((prevState) => ({
                                            ...prevState,
                                            searchFormFields: {
                                                ...prevState.searchFormFields,
                                                [field.fieldName]: newValue,
                                            },
                                        }));
                                    }
                                }}
                                placeholder=""
                                maxCount={2}
                            />
                        );

                    case false:
                        return (
                            <Select
                                aria-label={t(field.fieldLabel)}
                                aria-describedby={`${field.fieldName}Help`}
                                ref={(fieldset) => (singleSelectRefs.current[fieldName] = fieldset)}
                                options={getOptionsForField(field.fieldName).map((option) => ({
                                    label: option.label,
                                    value: option.value,
                                }))}
                                onValueChange={(newValue: string) => {
                                    const dataFlowSelection: DataFlowType = {
                                        value: newValue
                                    };
                                    setFormFieldsState((prevState) => ({
                                        ...prevState,
                                        searchFormFields: {
                                            ...prevState.searchFormFields,
                                            [field.fieldName]: dataFlowSelection,
                                        },
                                    }));
                                }}
                                placeholder=""
                            />
                        );

                    default:
                        return null;
                }

            case 'text':
                return (
                    <>
                        <Form.Control
                            aria-describedby={`${field.fieldName}Help`}
                            type="text"
                            name={field.fieldName}
                            value={safeStringValue(field.fieldName)}
                            onChange={handleInputChange}
                            className="pe-5 text-truncate"
                            style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }}
                        />
                        {/*Clear button*/}
                        <div className="position-relative">
                            {safeStringValue(field.fieldName) && (
                                <Button
                                    variant="link-dark"
                                    className="btn-sm bg-white border-0 position-absolute top-50 end-0 translate-middle-y me-2"
                                    onClick={() =>
                                        handleInputChange({
                                            target: { name: field.fieldName, value: '' },
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                >
                                    <i className="bi bi-x fs-6" aria-hidden="true"></i>
                                    <span className="visually-hidden">Clear text</span>
                                </Button>
                            )}
                        </div>
                    </>
                );

            case 'date':
                return (
                    <>
                        <DatePicker
                            placeholderText={t(SEARCH_FILTERS_DATE_RANGE_FROM_PLACEHOLDER)}
                            selected={safeStringValue(`${field.fieldName}From`) ? new Date(safeStringValue(`${field.fieldName}From`)) : null}
                            onChange={(date) => handleDateChange(date, `${field.fieldName}From`)}
                            ref={datePickers[`${field.fieldName}From`]}
                            onClickOutside={() => datePickers[`${field.fieldName}From`]?.current?.setOpen(false)}
                            maxDate={safeStringValue(`${field.fieldName}To`) ? new Date(safeStringValue(`${field.fieldName}To`)) : undefined}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control border-end-0 rounded-0"
                            wrapperClassName="w-full"
                        />
                        {/* Container for the clear button */}
                        <div
                            className={`position-relative ${!safeStringValue(`${field.fieldName}From`) ? '' : 'border-end-0'}`}
                        >
                            {/* Always render the clear button, but make it visible only when a date is selected */}
                            <Button
                                variant="link-dark"
                                className={`btn-sm bg-white border-0 border-start-0 ${!safeStringValue(`${field.fieldName}From`) ? 'd-none' : ''} position-absolute top-50 end-0 translate-middle-y`}
                                onClick={() => handleDateChange(null, `${field.fieldName}From`)}
                            >
                                <i className="bi bi-x fs-6" aria-hidden="true"></i>
                                <span className="visually-hidden">Clear date</span>
                            </Button>
                        </div>

                        <Button
                            variant="link-dark"
                            className="btn-sm bg-white border border-start-0"
                            onClick={() => datePickers[`${field.fieldName}From`]?.current?.setOpen(true)}
                        >
                            <i className="bi bi-calendar4" aria-hidden="true"></i>
                            <span className="visually-hidden">Date / time picker</span>
                        </Button>

                        <DatePicker
                            placeholderText={t(SEARCH_FILTERS_DATE_RANGE_TO_PLACEHOLDER)}
                            selected={safeStringValue(`${field.fieldName}To`) ? new Date(safeStringValue(`${field.fieldName}To`)) : null}
                            onChange={date => handleDateChange(date, `${field.fieldName}To`)}
                            ref={datePickers[`${field.fieldName}To`]}
                            onClickOutside={() => datePickers[`${field.fieldName}To`]?.current?.setOpen(false)}
                            minDate={safeStringValue(`${field.fieldName}From`) ? new Date(safeStringValue(`${field.fieldName}From`)) : undefined}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="form-control border-end-0 rounded-0"
                            wrapperClassName="w-full"
                        />
                        {/* Container for the clear button */}
                        <div
                            className={`position-relative ${!safeStringValue(`${field.fieldName}To`) ? '' : 'border-end-0'}`}
                        >
                            {/* Always render the clear button, but make it visible only when a date is selected */}
                            <Button
                                variant="link-dark"
                                className={`btn-sm bg-white border-0 border-start-0 ${!safeStringValue(`${field.fieldName}To`) ? 'd-none' : ''} position-absolute top-50 end-0 translate-middle-y`}
                                onClick={() => handleDateChange(null, `${field.fieldName}To`)}
                            >
                                <i className="bi bi-x fs-6" aria-hidden="true"></i>
                                <span className="visually-hidden">Clear date</span>
                            </Button>
                        </div>

                        <Button
                            variant="link-dark"
                            className="btn-sm bg-white border border-start-0"
                            onClick={() => datePickers[`${field.fieldName}To`]?.current?.setOpen(true)}
                            style={{ borderTopRightRadius: '0.375rem', borderBottomRightRadius: '0.375rem' }}
                        >
                            <i className="bi bi-calendar4" aria-hidden="true"></i>
                            <span className="visually-hidden">Date / time picker</span>
                        </Button>
                    </>
                );

            case 'checkbox':
                return (
                    <InputGroup.Text className="border-0 bg-transparent">
                        <Form.Check
                            type="checkbox"
                            id={field.fieldName}
                            aria-describedby={`${field.fieldName}Help`}
                            label={t(field.fieldLabel)}
                            checked={getCheckboxValue(formFieldsState.searchFormFields[field.fieldName])}
                            onChange={handleCheckboxChange}
                        />
                    </InputGroup.Text>
                );

            default:
                return null;
        }
    };

    const renderFieldGroup = (field: VisibilityFilter, index: number) => (
        <Form.Group className="mb-3" controlId={`SRCH${field.fieldName}`} key={index}>
            {field.fieldType === 'checkbox' ? (
                <Form.Label style={{ color: 'transparent' }}>&nbsp;</Form.Label>
            ) : (
                <Form.Label>{t(field.fieldLabel)}:</Form.Label>
            )}
            <InputGroup style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip(t(field.fieldLabel + "_TOOLTIP_TEXT"))}
                >
                    <Button variant="link" className="btn-sm border-end FormHelp">
                        <i className="bi bi-info-lg" aria-hidden="true"></i>
                        <span className="visually-hidden">Help with this field</span>
                    </Button>
                </OverlayTrigger>
                {renderField(field, field.fieldName)}
            </InputGroup>
        </Form.Group>
    );

    return (
        <>
            {fields.map((field, index) => {
                if (field.fullWidth) {
                    return (
                        <Row key={`fullWidthRow-${index}`}>
                            <Col sm={6} className="pe-0">
                                {renderFieldGroup(field, index)}
                            </Col>
                        </Row>
                    );
                } else {
                    if (!inRow) {
                        setInRow(true);
                    }

                    return (
                        <Col sm={6} key={index}>
                            {renderFieldGroup(field, index)}
                        </Col>
                    );
                }
            })}
        </>
    );
};

export default SearchField;

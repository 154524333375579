import React from 'react';
import { Row, Col, Stack, Alert, Button } from 'react-bootstrap';
import strings from "@/loc/strings.ts";
import {DYNAMIC_ALERT_KEYS} from "@/constants/EsapConstants.ts";
import {useTranslation} from "react-i18next";
import {SearchFilter} from "@/model/SearchFilter.ts";

interface AppliedFiltersProps {
    showFilters: boolean;
    searchFieldsState: {
        searchFormFields: SearchFilter;
    };
    handleRemoveFilter: (key: string) => void;
    getLabelForValue: (key: string, value: string) => string;
}

const AppliedFilters: React.FC<AppliedFiltersProps> = ({
                                                           showFilters,
                                                           searchFieldsState,
                                                           handleRemoveFilter,
                                                           getLabelForValue,
                                                       }) => {
    const { t } = useTranslation();

    return (
        <Row aria-label="List of applied filters">
            <Col xs={12}>
                <Stack direction="horizontal" gap={2} className="overflow-x-auto">
                    {/* Static Alert for Date Filters */}
                    <Alert className="SRTag d-none" variant="light">
                        <Alert.Heading className="SRTagInfo">
                            <small className="SRTagLabel">{strings.labels.submissionDateLabel}:</small>
                            <span className="SRTagValue">01/01/2024 - 31/01/2024</span>
                        </Alert.Heading>
                        <Button className="btn-light" size="sm" variant="light" data-bs-dismiss="alert" aria-label="Close">
                            <i className="bi bi-trash3" aria-hidden="true"></i>
                        </Button>
                    </Alert>

                    {/* Conditional Dynamic Alerts */}
                    {Object.entries(searchFieldsState.searchFormFields).map(([key, value]) => {
                        if (
                            !value ||
                            (key === strings.submissionDateFrom || key === strings.submissionDateTo ||
                                key === strings.dateOrPeriodCoveredFrom || key === strings.dateOrPeriodCoveredTo)
                        ) {
                            return null;
                        }

                        if (!value || (key === strings.sizeOfEntity && (!value || (typeof value === 'object' && 'value' in value && !value.value)))) {
                            return null;
                        }

                        if ((
                            key === strings.typeOfInformation ||
                            key === strings.sizeOfSubmittingEntity ||
                            key === strings.leiStatus ||
                            key === strings.countryOfRegisteredOffice ||
                            key === strings.industrySector ||
                            key === strings.originalLanguage ||
                            key === strings.legalFramework ||
                            key === strings.collectionBodyName ||
                            key === strings.homeMemberStateOfEntity ||
                            key === strings.hostMemberStateOfEntity
                        ) && Array.isArray(value) && value.length === 0) {
                            return null;
                        }

                        const fieldLabel = t(DYNAMIC_ALERT_KEYS[key as keyof typeof DYNAMIC_ALERT_KEYS] || key);

                        return (
                            <Alert className="SRTag" variant="light" key={key}>
                                <Alert.Heading className="SRTagInfo">
                                    <small className="SRTagLabel">{fieldLabel}:</small>
                                    <span className="SRTagValue">
                  {Array.isArray(value)
                      ? value.map(v =>
                          typeof v === 'object' && 'value' in v
                              ? getLabelForValue(key, v.value ?? '')
                              : getLabelForValue(key, String(v ?? ''))
                      ).join('; ')
                      : (typeof value === 'boolean'
                              ? value.toString()
                              : (typeof value === 'object' && 'value' in value
                                  ? getLabelForValue(key, value.value ?? '')
                                  : getLabelForValue(key, String(value ?? '')))
                      )
                  }
                </span>
                                </Alert.Heading>
                                <Button className="btn-light" size="sm" variant="light"
                                        data-bs-dismiss="alert"
                                        aria-label="Close"
                                        onClick={() => handleRemoveFilter(key)}>
                                    <i className="bi bi-trash3" aria-hidden="true"></i>
                                </Button>
                            </Alert>
                        );
                    })}

                    {/* Conditional Date Filters */}
                    {showFilters && (
                        (searchFieldsState.searchFormFields[strings.submissionDateFrom] ||
                            searchFieldsState.searchFormFields[strings.submissionDateTo] ||
                            searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] ||
                            searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo]) && (
                            <>
                                {/* Submission Date Alert */}
                                {(searchFieldsState.searchFormFields[strings.submissionDateFrom] ||
                                    searchFieldsState.searchFormFields[strings.submissionDateTo]) && (
                                    <Alert className="SRTag" variant="light" key={strings.submissionDate}>
                                        <Alert.Heading className="SRTagInfo">
                                            <small className="SRTagLabel">{strings.labels.submissionDateLabel}:</small>
                                            <span className="SRTagValue">
                      {`${searchFieldsState.searchFormFields[strings.submissionDateFrom] || ''} - ${searchFieldsState.searchFormFields[strings.submissionDateTo] || ''}`}
                    </span>
                                        </Alert.Heading>
                                        <Button className="btn-light" size="sm" variant="light"
                                                data-bs-dismiss="alert"
                                                aria-label="Close" onClick={() => {
                                            handleRemoveFilter(strings.submissionDateFrom);
                                            handleRemoveFilter(strings.submissionDateTo);
                                        }}>
                                            <i className="bi bi-trash3" aria-hidden="true"></i>
                                        </Button>
                                    </Alert>
                                )}

                                {/* Date Covered Alert */}
                                {(searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] ||
                                    searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo]) && (
                                    <Alert className="SRTag" variant="light" key={strings.dateOrPeriodCovered}>
                                        <Alert.Heading className="SRTagInfo">
                                            <small className="SRTagLabel">{strings.labels.dateOrPeriodCoveredLabel}:</small>
                                            <span className="SRTagValue">
                      {`${searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredFrom] || ''} - ${searchFieldsState.searchFormFields[strings.dateOrPeriodCoveredTo] || ''}`}
                    </span>
                                        </Alert.Heading>
                                        <Button className="btn-light" size="sm" variant="light"
                                                data-bs-dismiss="alert"
                                                aria-label="Close" onClick={() => {
                                            handleRemoveFilter(strings.dateOrPeriodCoveredFrom);
                                            handleRemoveFilter(strings.dateOrPeriodCoveredTo);
                                        }}>
                                            <i className="bi bi-trash3" aria-hidden="true"></i>
                                        </Button>
                                    </Alert>
                                )}
                            </>
                        )
                    )}
                </Stack>
            </Col>
        </Row>
    );
};

export default AppliedFilters;

import React, { useEffect, useCallback, useState } from "react";
import { fetchCsvFile } from "../../../services/DatasetDetailsService/DatasetDetailsService";
import { Spinner } from "react-bootstrap";

interface CsvDisplayProps {
  datasetRealId: string;
  fileId: string;
}

const CsvDisplayNew: React.FC<CsvDisplayProps> = ({datasetRealId, fileId,}) => {
  const [rows, setRows] = useState<string[][]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [contentError, setContentError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const itemsPerPage = 10;
  const fetchCsvPage = useCallback(async (pageNumber: number) => {
    setLoading(true);
    setContentError(false); 
    try {
      const content =  await fetchCsvFile(datasetRealId, fileId, pageNumber);
      if (content && Array.isArray(content.pageData) && content.pageData.length > 0) {
        const rowsData = content.pageData.map((row) => row.split(","));
        setRows(rowsData);
        setTotalPages(Math.max(0, Math.ceil(content.totalRows / itemsPerPage) - 1));
        setCurrentPage(pageNumber);
      } else {throw new Error(`Invalid or empty CSV content for page ${pageNumber}`);}
    } catch (error) {
      console.error(`Error fetching CSV data for page ${pageNumber}:`, error);
      setContentError(true);
      setErrorMessage("An error occurred while fetching the file.");
    } finally {
      setLoading(false);
    }
  }, [datasetRealId, fileId]);

 
  useEffect(() => {
    fetchCsvPage(0);
  },[datasetRealId, fileId, fetchCsvPage]);

 
  const handleClick = (button: string) => {
    let page = currentPage;
    switch (button) {
      case "first":
        page = 0;
        break;
      case "prev":
        page = Math.max(currentPage - 1, 0);
        break;
      case "next":
        page = Math.min(currentPage + 1, totalPages);
        break;
      case "last":
        page = totalPages;
        break;
      default:
        break;
    }

    if (page !== currentPage) {
      fetchCsvPage(page); 
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      {loading && !contentError ? (
        <Spinner />
      ) : contentError ? (
        <div className="alert alert-danger mt-3 text-center">
          {errorMessage}
        </div>
      ) : (
        <div>
          <div className="bg-white border text-center rounded-1 mt-3">
            <table className="table table-sm table-striped-columns table-hover m-0">
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="Pagination d-flex justify-content-end mt-3">
            {/* First button */}
            <button
              className="btn btn-sm btn-light link-dark border-0 mx-1 d-none d-md-inline"
              onClick={() => handleClick("first")} disabled={currentPage === 0}>
              <i className="bi bi-chevron-bar-left" aria-hidden="true"></i>
              First
            </button>
            {/* Previous button */}
            <button
              className="btn btn-sm btn-light link-dark border-0 mx-1"
              onClick={() => handleClick("prev")} disabled={currentPage === 0}>
              <i className="bi bi-chevron-left" aria-hidden="true"></i> 
              Previous
            </button>
            {/* Next button */}
            <button
              className="btn btn-sm btn-light link-dark border-0 mx-1"
              onClick={() => handleClick("next")} disabled={currentPage >= totalPages}>
             <i className="bi bi-chevron-right" aria-hidden="true"></i>
             Next 
            </button>
            {/* Last button */}
            <button
              className="btn btn-sm btn-light text-dark border-0 mx-1 d-none d-md-inline"
              onClick={() => handleClick("last")} disabled={currentPage >= totalPages}            >
              <i className="bi bi-chevron-bar-right" aria-hidden="true"></i>
              Last
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvDisplayNew;

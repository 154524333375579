/* eslint-disable @typescript-eslint/no-explicit-any */

import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import './ExpandableTableData.css';
import { ACKNOWLEDGEMENT_STATUS, MONITORING_DATA_QUALITY_TABLE_ACKNOWLEDGEMENT_STATUS_CONFIRM_LABEL, MONITORING_DATA_QUALITY_TABLE_ACKNOWLEDGEMENT_STATUS_IGNORE_LABEL, MONITORING_DATA_QUALITY_TABLE_LOADING_ISSUES, MONITORING_DATA_QUALITY_TABLE_NO_QUALITY_ISSUES, MONITORING_DATA_QUALITY_TABLE_NO_RESULTS, MONITORING_DATA_QUALITY_TABLE_PRIORITY_HIGH_LABEL, MONITORING_DATA_QUALITY_TABLE_PRIORITY_LOW_LABEL, MONITORING_DATA_QUALITY_TABLE_PRIORITY_MEDIUM_LABEL, MONITORING_DATA_QUALITY_TABLE_SAVE_BUTTON_TEXT, MONITORING_DATA_QUALITY_TABLE_STATUS_RESOLVED_LABEL, MONITORING_DATA_QUALITY_TABLE_STATUS_UNDER_RESOLUTION_LABEL, MONITORING_DATA_QUALITY_TABLE_STATUS_UNRESOLVABLE_LABEL, MONITORING_DATA_QUALITY_TABLE_STATUS_UNRESOLVED_LABEL, PRIORITY, STATUS } from "@/constants/EsapConstants";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { saveQualityIssueOfDataset, SaveQualityIssueRequest } from "@/services/MonitoringService/MonitoringService";
import Toast from "../../Common/Toast.tsx";
import { TableDatasetRow } from "./ExpandableTableData.ts";
import { SaveQualityIssueResponse } from "@/model/MonitoringResults.ts";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  expandedRowContent: any;
  expandedRowIndex: string;
  reportId: string;
  isLatest: boolean;
}

interface DropdownOptions {
  label: string;
  value: string;
}

export function ExpandableTable<TData, TValue>({
    columns,
    data,
    expandedRowContent,
    expandedRowIndex,
    reportId,
    isLatest
  }: DataTableProps<TData, TValue>) {

  const [showSuccessSaveToast, setShowSuccessSaveToast] = useState(false);
  const [showFailSaveToast, setShowFailSaveToast] = useState(false);
  const [showWarningSaveToast, setShowWarningSaveToast] = useState(false);
  const [expandedRowContentState, setExpandedRowContentState] = useState<any>(expandedRowContent);
  useEffect(() => { setExpandedRowContentState(expandedRowContent)}, [expandedRowContent]);

  const ACKNOWLEDGEMENT_STATUS_OPTIONS: DropdownOptions[] = [
    {label: "", value: ""},
    {label: t(MONITORING_DATA_QUALITY_TABLE_ACKNOWLEDGEMENT_STATUS_CONFIRM_LABEL), value: ACKNOWLEDGEMENT_STATUS.CONFIRM},
    {label: t(MONITORING_DATA_QUALITY_TABLE_ACKNOWLEDGEMENT_STATUS_IGNORE_LABEL), value: ACKNOWLEDGEMENT_STATUS.IGNORE}
  ]

  const PRIORITY_OPTIONS: DropdownOptions[] = [
    {label: "", value: ""},
    {label: t(MONITORING_DATA_QUALITY_TABLE_PRIORITY_LOW_LABEL), value: PRIORITY.LOW},
    {label: t(MONITORING_DATA_QUALITY_TABLE_PRIORITY_MEDIUM_LABEL), value: PRIORITY.MEDIUM},
    {label: t(MONITORING_DATA_QUALITY_TABLE_PRIORITY_HIGH_LABEL), value: PRIORITY.HIGH}
  ]

  const STATUS_OPTIONS: DropdownOptions[] = [
    {label: "", value: ""},
    {label: t(MONITORING_DATA_QUALITY_TABLE_STATUS_RESOLVED_LABEL), value: STATUS.RESOLVED},
    {label: t(MONITORING_DATA_QUALITY_TABLE_STATUS_UNDER_RESOLUTION_LABEL), value: STATUS.UNDER_RESOLUTION},
    {label: t(MONITORING_DATA_QUALITY_TABLE_STATUS_UNRESOLVED_LABEL), value: STATUS.UNRESOLVED},
    {label: t(MONITORING_DATA_QUALITY_TABLE_STATUS_UNRESOLVABLE_LABEL), value: STATUS.UNRESOLVABLE}
  ]

  function handleAcknowledgementStatusChange(event: React.ChangeEvent<HTMLSelectElement>, index: number) {
    expandedRowContentState[index].acknowledgementStatus = event.target.value;
    if (event.target.value === ACKNOWLEDGEMENT_STATUS.IGNORE) {
      expandedRowContentState[index].priority = "";
      expandedRowContentState[index].status = "";
    }
    setExpandedRowContentState([...expandedRowContentState]);
  }

  function handlePriorityChange(event: React.ChangeEvent<HTMLSelectElement>, index: number) {
    expandedRowContentState[index].priority = event.target.value;
    setExpandedRowContentState([...expandedRowContentState]);
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLSelectElement>, index: number) {
    expandedRowContentState[index].status = event.target.value;
    setExpandedRowContentState([...expandedRowContentState]);
  }

  async function handleSaveAction(datasetIndex: string, qualityIssueIndex: number) {
    const request: SaveQualityIssueRequest = {
      reportId: reportId,
      datasetId: (data[+datasetIndex] as TableDatasetRow).datasetId,
      typeOfIssue: expandedRowContentState[qualityIssueIndex].typeOfIssue,
      status: expandedRowContentState[qualityIssueIndex].acknowledgementStatus === ACKNOWLEDGEMENT_STATUS.IGNORE ? "" : expandedRowContentState[qualityIssueIndex].status,
      priority: expandedRowContentState[qualityIssueIndex].acknowledgementStatus === ACKNOWLEDGEMENT_STATUS.IGNORE ? "" : expandedRowContentState[qualityIssueIndex].priority,
      acknowledgementStatus: expandedRowContentState[qualityIssueIndex].acknowledgementStatus
    }
    const saveResponse: SaveQualityIssueResponse | null = await saveQualityIssueOfDataset(request);
    if (saveResponse && saveResponse.success) {
      setShowSuccessSaveToast(true);
    } else if (saveResponse && saveResponse.locked) {
      setShowWarningSaveToast(true);
    } else {
      setShowFailSaveToast(true);
    }
  }
  
  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel()
  });

  if (table.getIsSomeRowsExpanded()) {
    table.getRowModel().rows?.map((row) => {
      if (row.getIsExpanded() && row.id != expandedRowIndex) {
        row.toggleExpanded();
      }
    })
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-sm FSsm">
          <thead className="table-light">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  )
                })}
                {isLatest && table.getIsSomeRowsExpanded() && (
                  <th></th>
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <tr
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>

                  {row.getIsExpanded() && expandedRowContentState && expandedRowContentState.waitResponse && (
                    <tr key={["loadingMessage", '-', row.id].join()}>
                      <td key={["loadingEmptyColumn", '-', row.id].join()}></td> 
                      <td key={["loadingMessageContent", '-', row.id].join()} colSpan={columns.length-1} className="text-primary bluebag"> 
                        {t(MONITORING_DATA_QUALITY_TABLE_LOADING_ISSUES)}
                      </td>
                    </tr>
                  )}

                  {row.getIsExpanded() && expandedRowContentState && expandedRowContentState.length > 0 && (
                    <>
                      {expandedRowContentState.map((expandedContentRow: any, index: number) => 
                        <tr key={expandedContentRow.rowId}>
                          <td key={["emptyDatasetId", '-', expandedContentRow.rowId].join()}></td>
                          <td key={["fileTypeCode", '-', expandedContentRow.rowId].join()}>
                            {expandedContentRow.fileTypeCode}
                          </td>
                          <td key={["issueTimestampDate", '-', expandedContentRow.rowId].join()}>
                            {expandedContentRow.issueTimestampDate} <small>{expandedContentRow.issueTimestampTime}</small>
                          </td>
                          <td key={["errorMessage", '-', expandedContentRow.rowId].join()}>
                            {expandedContentRow.errorMessage}
                          </td>
                          <td key={["publicationStatus", '-', expandedContentRow.rowId].join()}>
                            {expandedContentRow.publicationStatus}
                          </td>
                          <td key={["sealValidation", '-', expandedContentRow.rowId].join()}>
                            {expandedContentRow.sealValidation}
                          </td>
                          <td key={["acknowledgementStatus", '-', expandedContentRow.rowId].join()}>
                            {isLatest && (
                              <select
                                  className="form-select form-select-sm"
                                  value={expandedContentRow.acknowledgementStatus}
                                  onChange={(e) => handleAcknowledgementStatusChange(e, index)}
                              >
                                  {ACKNOWLEDGEMENT_STATUS_OPTIONS.map(option => (
                                      <option key={option.label} value={option.value}>
                                          {t(option.label)}
                                      </option>
                                  ))}
                              </select>
                            )}
                            {!isLatest && (
                              <>
                                {ACKNOWLEDGEMENT_STATUS_OPTIONS.map(option => (
                                  <>
                                    {option.value == expandedContentRow.acknowledgementStatus && (
                                      <span>{option.label}</span>
                                    )}
                                  </>
                                ))}
                              </>
                            )}
                          </td>
                          <td key={["priority", '-', expandedContentRow.rowId].join()}>
                            {isLatest && (
                              <select
                                  className="form-select form-select-sm"
                                  value={expandedContentRow.priority}
                                  onChange={(e) => handlePriorityChange(e, index)}
                                  disabled={expandedContentRow.acknowledgementStatus !== ACKNOWLEDGEMENT_STATUS.CONFIRM}
                              >
                                  {PRIORITY_OPTIONS.map(option => (
                                      <option key={option.label} value={option.value}>
                                          {t(option.label)}
                                      </option>
                                  ))}
                              </select>
                            )}
                            {!isLatest && (
                              <>
                                {PRIORITY_OPTIONS.map(option => (
                                  <>
                                    {option.value == expandedContentRow.priority && (
                                      <span>{option.label}</span>
                                    )}
                                  </>
                                ))}
                              </>
                            )}
                          </td>
                          <td key={["status", '-', expandedContentRow.rowId].join()}>
                            {isLatest && (
                              <select
                                  className="form-select form-select-sm"
                                  value={expandedContentRow.status}
                                  onChange={(e) => handleStatusChange(e, index)}
                                  disabled={expandedContentRow.acknowledgementStatus !== ACKNOWLEDGEMENT_STATUS.CONFIRM}
                              >
                                  {STATUS_OPTIONS.map(option => (
                                      <option key={option.label} value={option.value}>
                                          {t(option.label)}
                                      </option>
                                  ))}
                              </select>
                            )}
                            {!isLatest && (
                              <>
                                {STATUS_OPTIONS.map(option => (
                                  <>
                                    {option.value == expandedContentRow.status && (
                                      <span>{option.label}</span>
                                    )}
                                  </>
                                ))}
                              </>
                            )}
                          </td>
                          {isLatest && (
                            <td key={["saveAction", '-', expandedContentRow.rowId].join()}>
                              <button 
                                type="button"
                                className="btn btn-primary"
                                onClick={() => handleSaveAction(row.id, index)}
                                disabled={ !expandedContentRow.acknowledgementStatus || 
                                          (expandedContentRow.acknowledgementStatus === ACKNOWLEDGEMENT_STATUS.CONFIRM && (!expandedContentRow.priority || !expandedContentRow.status))}
                              >
                                {t(MONITORING_DATA_QUALITY_TABLE_SAVE_BUTTON_TEXT)}
                              </button>
                            </td>
                          )}
                        </tr>
                      )}
                    </>
                  )}
                  
                  {row.getIsExpanded() && expandedRowContentState && !expandedRowContentState.waitResponse && !expandedRowContentState.length && (
                    <tr key={["noQualityIssuesMessage", '-', row.id].join()}>
                      <td key={["noQualityIssuesEmptyColumn", '-', row.id].join()}></td>
                      <td key={["noQualityIssuesMessageContent", '-', row.id].join()} colSpan={columns.length}>
                        {t(MONITORING_DATA_QUALITY_TABLE_NO_QUALITY_ISSUES)}
                      </td>
                    </tr>
                  )}
                </>
              ))
            ) : (
              <tr key="noResultsMessage">
                <td key="noResultsMessageContent" colSpan={columns.length}>
                  {t(MONITORING_DATA_QUALITY_TABLE_NO_RESULTS)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Toast 
        message="Saved successfully" 
        isVisible={showSuccessSaveToast} 
        onClose={() => setShowSuccessSaveToast(false)} 
        type="success"
      />

      <Toast
        message="Quality issues are locked, please refresh the page"
        isVisible={showWarningSaveToast}
        onClose={() => setShowWarningSaveToast(false)}
        type="warning"
      />

      <Toast
        message="Save failed"
        isVisible={showFailSaveToast}
        onClose={() => setShowFailSaveToast(false)}
        type="danger"
      />
    </>
  )
}
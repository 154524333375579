import {backendCoreUrl} from "../ServiceEndpoints.ts";
import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";

export async function uploadFileToServer(formData: FormData, useCase: string): Promise<{ message: string; success: boolean }> {
    try {
        const url = `${backendCoreUrl}/submission/upload?useCase=${useCase}`;
        const response = await fetchWrapper(url, { method: 'POST', body: formData });
        if (!response.ok) {
            const errorText = await response.text(); // Get error details from the server
            const errorDetails = JSON.parse(errorText);
            const errorMessage = errorDetails.message;
            return { message: errorMessage || 'Failed to upload file.', success: false };
        }
        const successMessage = await response.text();
        return { message: successMessage, success: true };
    } catch (error) {
        console.error('Error uploading file:', error);
        return { message: 'An error occurred while uploading the file.', success: false };
    }
}

export const fetchDropdownOptions = () => {
    return fetchWrapper(`${import.meta.env.VITE_REACT_APP_DRUPAL_API_URL}/jsonapi/taxonomy/documentation_and_support`, { method: 'GET' });
};

export const fetchVersionRelatedDocumentation = (dataFlow: string, version: string) => {
    return fetchWrapper(`${import.meta.env.VITE_REACT_APP_DRUPAL_API_URL}/jsonapi/content/documentation_and_support?data_flow=${dataFlow}&system_version=${version}`, { method: 'GET' });
};


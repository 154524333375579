import { Button, Col, Form, InputGroup, OverlayTrigger} from "react-bootstrap";
import {
    RE_FILE_UPLOAD_HELPER_TEXT,
    RE_FILE_UPLOAD_TOOLTIP_TEXT,
    RE_UPLOAD_TEST_DATA_BUTTON_TEXT, RE_UPLOADING_TEST_DATA_TEXT, Roles
} from "../../constants/EsapConstants.ts";
import React, {useEffect, useImperativeHandle, useState, forwardRef} from "react";
import {renderTooltip} from "../../utils/tooltips.tsx";
import {UserInfo} from "../../model/UserInfo.ts";
import {useUserInfo} from "../../hooks/useUserInfo.ts";
import {t} from "i18next";


interface FileInputProps {
    onFileChange: (file: File | null) => void; // Define the type of the callback prop
    onMessageChange: (message: Message) => void;
    isUploading: boolean;
    showButton?: boolean;
    labelMessage?:string;
}

export interface Message {
    message: string;
    type: 'success' | 'danger' | '';
}

export const UploadFile = forwardRef<HTMLInputElement | null, FileInputProps>(  ({ onFileChange, onMessageChange, isUploading  , showButton=true, labelMessage}, ref)  => {

    // const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'danger' | ''>('');

    const hasRoles = (roles: string[]) => {
        return userInfo?.roles.some((r) => roles.includes(r));
    };
    const [userInfo, setUserInfo] = useState<UserInfo | null>();

    const {user} = useUserInfo();

    useEffect(() => {

        setUserInfo(user);

    }, [user, setUserInfo]);


    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.size <= 5 * 1024 * 1024 && file.name.endsWith('.zip')) {
            onFileChange(file); // Pass the file to the parent component
            // setSelectedFile(file);
            setMessage('');
        } else {
            // setSelectedFile(null);
            setMessage('Please select a valid .zip file of size up to 5 MB.');
            setMessageType('danger');
        }
        onMessageChange({message: message, type: messageType});
    };

    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    // Expose internal input ref to the parent
    useImperativeHandle(ref, () => {
        if(fileInputRef.current) {
            return fileInputRef.current;
        }
        return new HTMLInputElement();
    });

    return (
       <>
            <Col lg={showButton?8:12}>
                <Form.Group className="FormComponent mb-3" controlId="TSTestData">
                    <Form.Label>{labelMessage}</Form.Label>
                    <InputGroup>
                        <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip(t(RE_FILE_UPLOAD_TOOLTIP_TEXT))}
                        >
                            <Button
                                variant="link"
                                id="TSTestDataHelp"
                                className="border-end FormHelp"
                            >
                                <i className="bi bi-info-lg" aria-hidden="true"></i>
                                <span className="visually-hidden">Help with this field</span>
                            </Button>
                        </OverlayTrigger>
                        <Form.Control
                            type="file"
                            required={true}
                            aria-describedby="TSTestDataHelp TSTestDataHelp2"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            accept={'.zip'}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select a file.
                        </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text id="TSTestDataHelp2" className="mt-2">
                        {t(RE_FILE_UPLOAD_HELPER_TEXT)}
                    </Form.Text>
                </Form.Group>
            </Col>
            {showButton &&
            <Col lg={4} className="d-flex align-items-center pt-1">
                <Button
                    variant="primary"
                    type={'submit'}
                    className="mb-3"
                    // onClick={handleUpload}
                    disabled={isUploading || !hasRoles([Roles.DATA_FLOW_ROLE])}
                >
                    <i className="bi bi-upload me-2"></i>
                    {isUploading ? t(RE_UPLOADING_TEST_DATA_TEXT) : t(RE_UPLOAD_TEST_DATA_BUTTON_TEXT)}
                </Button>
            </Col>
            }
      </>
    )
});
import React, {useState} from 'react';

import {
    HS_DOWNLOAD_THE_FILE,
    HS_DOWNLOAD_FEEDBACK,
    HS_RESEND_FEEDBACK,
    HS_DETAILS,
    HS_PROCESSING_TIME,
    HS_VALIDATION,
    HS_ERROR_CODES,
    HS_FEEDBACK_FILE_NAME,
    HS_FILETYPECODE,
    HS_PATH_TO_THE_REJECTED_DATA,
    HS_PROCESSING_START_END_DATE, HS_RECEPTION_DATE
} from "../../constants/EsapConstants.ts";
import { useTranslation } from "react-i18next";
import {Submission} from "./types.tsx";


import HistoryDetailsModal from "./HistoryDetailsModal.tsx";
import {handleDownload, resendFeedback} from "@/services/HistoryOfSubmission/HistoryOfSubmissionService.ts";



interface HistoryResultsPopulateProps {
    results: Submission[]; // Current page's data
    pageNumber: number; // Current page number
    totalPages: number; // Total pages
    onPageChange: (action: string) => void; // Function to handle page change
}


// Component to render individual data blocks
const DataBlock: React.FC<{ item: Submission }> = ({ item }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(false);


    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleResendFeedback = async (filename: string) => {
        try {
            await resendFeedback(filename);
            setIsResendDisabled(true); // Disable button after successful execution
        } catch (error) {
            console.error('Error resending feedback:', error);
        }
    };
    return (
        <div className="card rounded-1 mb-5">
            <div className="card-body">
                <div className="card-title fs-5 fw-semibold">{item.Filename}</div>
                <dl className="DList DList-Compact DList-Separators DList-6-6 DList-xl-3-9 mb-0">
                    <dt>{t(HS_RECEPTION_DATE)}:</dt>
                    <dd><span className="text-nowrap">{item.ReceptionDate}</span></dd>
                    <dt>{t(HS_PROCESSING_START_END_DATE)}:</dt>
                    <dd>
                        <span className="text-nowrap">
                            {item.ProcessingStartEndDate.Start.split(" ")[0]}&nbsp;<small
                            className="fw-normal text-secondary">{item.ProcessingStartEndDate.Start.split(" ")[1]}</small>&nbsp;-&nbsp;
                            {item.ProcessingStartEndDate.End.split(" ")[0]}&nbsp;<small
                            className="fw-normal text-secondary">{item.ProcessingStartEndDate.End.split(" ")[1]}</small>
                        </span>
                    </dd>
                    <dt>{t(HS_PROCESSING_TIME)}:</dt>
                    <dd>{item.ProcessingTime}</dd>
                    <dt>{t(HS_FEEDBACK_FILE_NAME)}:</dt>
                    <dd>{item.FeedbackFileName}</dd>
                    <dt>{t(HS_FILETYPECODE)}:</dt>
                    <dd>{item.FileTypeCode}</dd>
                    <dt>{t(HS_VALIDATION)}:</dt>
                    <dd>{item.ValidationOutcome}</dd>
                    <dt>{t(HS_ERROR_CODES)}:</dt>
                    <dd>{item.ErrorCodes}</dd>
                    <dt>{t(HS_PATH_TO_THE_REJECTED_DATA)}:</dt>
                    <dd>{item.PathToRejectedData}</dd>
                </dl>
            </div>


            <div className="card-footer bg-lighter p-0 d-flex justify-content-between">
                <div className="d-xl-flex justify-content-start w-100">
                    <button type="button" className="btn btn-light link-primary px-2 px-lg-3 rounded-0 border-end"
                            onClick={() => handleDownload(item.Filename, "file")}>
                        <i className="bi bi-file-arrow-down me-2" aria-hidden="true"></i>{t(HS_DOWNLOAD_THE_FILE)}
                    </button>
                    <button type="button" className="btn btn-light link-primary px-2 px-lg-3 rounded-0 border-end"
                            onClick={() => handleDownload(item.FeedbackFileName, "feedback")}>
                        <i className="bi bi-download me-2" aria-hidden="true"></i>{t(HS_DOWNLOAD_FEEDBACK)}
                    </button>
                    <button
                        type="button"
                        className="btn btn-light link-primary px-2 px-lg-3 rounded-0 border-end"
                        onClick={() =>  handleResendFeedback(item.Filename)}
                        disabled={isResendDisabled}>
                        <i className="bi bi-send me-2" aria-hidden="true"></i>
                        {t(HS_RESEND_FEEDBACK)}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary px-2 px-lg-3 rounded-0 ms-auto"
                        onClick={handleShowModal}>
                        <i className="bi bi-calculator me-2" aria-hidden="true"></i>
                        {t(HS_DETAILS)}
                    </button>

                    {/* Modal Component */}
                    <HistoryDetailsModal show={showModal} handleClose={handleCloseModal}/>

                </div>
            </div>

        </div>


    );
};

// Main Component to populate the history results
const HistoryResultsPopulate: React.FC<HistoryResultsPopulateProps> = ({
                                                                           results,
                                                                           pageNumber,
                                                                           totalPages,
                                                                           onPageChange
                                                                       }) => {
    const {t} = useTranslation();

    return (
        <div>
            {/* Render Results */}
            {results.map((item: Submission, index: number) => (
                <DataBlock key={index} item={item} />
            ))}

            {/* Pagination Controls */}
            <div className="Pagination d-flex justify-content-end mt-5 py-2 border-top">
                <button
                    onClick={() => onPageChange('first')}
                    disabled={pageNumber === 1}
                    className="btn btn-outline-secondary mx-1"
                >
                    {t('First')}
                </button>
                <button
                    onClick={() => onPageChange('previous')}
                    disabled={pageNumber === 1}
                    className="btn btn-outline-secondary mx-1"
                >
                    {t('Previous')}
                </button>
                <span className="mx-3">
                    {t('Page')} {pageNumber} {t('of')} {totalPages}
                </span>
                <button
                    onClick={() => onPageChange('next')}
                    disabled={pageNumber === totalPages}
                    className="btn btn-outline-secondary mx-1"
                >
                    {t('Next')}
                </button>
                <button
                    onClick={() => onPageChange('last')}
                    disabled={pageNumber === totalPages}
                    className="btn btn-outline-secondary mx-1"
                >
                    {t('Last')}
                </button>
            </div>
        </div>
    );
};



export default HistoryResultsPopulate;
import {useState, useEffect, useRef, FormEvent} from 'react';
import HistoryResultsPopulate from './HistoryResultsPopulate.tsx';
import HistoryFiltersPopulate from './HistoryFiltersPopulate.tsx';
import {HS_HISTORY_OF_SUBMISSIONS, HS_FILE_UPLOAD_AND_HISTORY_OF_SUBMISSIONS} from '../../constants/EsapConstants.ts';
import {useTranslation} from 'react-i18next';
import {Submission} from './types.tsx';
import {Message, UploadFile} from '../Common/UploadFile.tsx';
import {Alert, Form, Row} from 'react-bootstrap';
import {uploadFileToServer} from '../../services/ReportingEntitiesService/ReportingEntitiesService.ts';
import {fetchResults} from '@/services/HistoryOfSubmission/HistoryOfSubmissionService.ts';


const HistoryOfSubmission = () => {
    const {t} = useTranslation();
    const [data, setData] = useState<Submission[]>([]);
    const [pageNumber, setPageNumber] = useState(1);
    const itemsPerPage = 2;
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const [paginatedResults, setPaginatedResults] = useState<Submission[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchResults();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const startIdx = (pageNumber - 1) * itemsPerPage;
            const endIdx = Math.min(startIdx + itemsPerPage, data.length);
            setPaginatedResults(data.slice(startIdx, endIdx));
        }
    }, [pageNumber, itemsPerPage, data]);

    const handlePageChange = (action: string) => {
        switch (action) {
            case 'first':
                setPageNumber(1);
                break;
            case 'previous':
                setPageNumber((prev) => Math.max(prev - 1, 1));
                break;
            case 'next':
                setPageNumber((prev) => Math.min(prev + 1, totalPages));
                break;
            case 'last':
                setPageNumber(totalPages);
                break;
            default:
                break;
        }
    };

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const handleFileChange = (file: File | null) => {
        setSelectedFile(file);
    };
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'success' | 'danger' | ''>('');
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [validated, setValidated] = useState(false);

    const handleMessageChange = (newMessage: Message) => {
        setMessage(newMessage.message);
        setMessageType(newMessage.type);
    };

    const handleUpload = async (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);

        if (!selectedFile) {
            setMessage('Please fill in required fields.');
            setMessageType('danger');
            return;
        }

        event.preventDefault();
        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const result = await uploadFileToServer(formData, 'SUBMISSION');
            setMessage(result.message);
            setMessageType(result.success ? 'success' : 'danger');
            setSelectedFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (error) {
            setMessage('An unexpected error occurred.');
            setMessageType('danger');
        } finally {
            setSelectedFile(null);
            setIsUploading(false);
            setValidated(false);
        }
    };

    return (
        <main>
            <div className="Intro py-2 py-lg-3">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1 className="fs-4 text-center">
                                {t(HS_FILE_UPLOAD_AND_HISTORY_OF_SUBMISSIONS)}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <Form noValidate validated={validated} onSubmit={handleUpload} className="bg-light py-5">
                <div className="container">
                    <div className="row py-3">
                        <UploadFile onFileChange={handleFileChange} onMessageChange={handleMessageChange}
                                    isUploading={isUploading} ref={fileInputRef} labelMessage="File upload"></UploadFile>
                        <Row>
                            {message && (
                                <Alert variant={messageType} className="mt-3">
                                    {message}
                                </Alert>
                            )}
                        </Row>
                    </div>
                </div>
            </Form>

            <div className="container">
                <div className="row">
                    <HistoryFiltersPopulate/>
                    <div className="col-lg-8 p-3 p-lg-5 pe-lg-1">
                        <h2 className="pb-3 mb-3 fs-4">{t(HS_HISTORY_OF_SUBMISSIONS)}</h2>
                        <HistoryResultsPopulate
                            results={paginatedResults}
                            pageNumber={pageNumber}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default HistoryOfSubmission;

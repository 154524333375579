import {DatasetResponse, File} from "../../model/InfoviewerResult";
import {downloadServiceUrl, infoviewerUrl} from "../ServiceEndpoints";
import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";
import { DataFlowTypes } from "../../constants/EsapConstants.ts";
import { DATSSRData } from "../../model/DATSSRData.ts";
import {  DrupalDatasetIcon, DrupalIconsConfiguration } from "../../model/DatasetDetailsIcons.ts";
import { CartValidations, BulkDownloadResponse, BulkDownloadResult, BulkUuidResponse, BulkValidationResponse } from "../../model/CartValidations.ts";
import { CsvResponse } from "@/model/CsvResponse.ts";

let controller: AbortController | null = null;

interface FailedFile {
    failedToDisplay: boolean;
}

interface FailedDataset {
    numberPassed: boolean;
    sizePassed: boolean;
}

export const calculateIfNeedsProcessing = (datasetResponse: DatasetResponse, file: File) => {
    return ((datasetResponse.dataFlowType === DataFlowTypes.DATSSR
                && file.fileType === 'XML'
            ));
}

export async function fetchDatasetFileset(datasetId: string): Promise<DatasetResponse | null> {
    try {
        const url = `${infoviewerUrl}/v1/datasets/${datasetId}`;
        const response = await fetchWrapper(url);
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(`Request failed with status: ${response.status}`);
        }
    } catch (error) {
        console.error("API error:", error);
        return null;
    }
}

export async function fetchFileContents(datasetId: string, fileId: string, fileType: string, needProcessing: boolean): Promise<string | Blob | object | object[] | undefined> {
    try {
        // TODO Use this here for moving load to Storage account.
        //const url = `${infoviewerFilesUrl}/${datasetId}/files/${fileId}`;
        // TODO use similar approach for all file fetches (infoviewer/download service).
        const processTextPart = needProcessing ? '/process' : '';
        const url = `${infoviewerUrl}/v1/datasets${processTextPart}/${datasetId}/files/${fileId}`;
        if (controller) {
            controller.abort();
        }
        controller = new AbortController();
        const signal = controller.signal;
   
        const response = await fetchWrapper(url,{signal});

        if (!response.ok) {
            if (response.status === 400) {
                throw new Error("Bad Request");
            } else if (response.status === 404) {
                throw new Error("The file is not available.");
            } else if (response.status === 500) {
                const errorBody = await response.json().catch(() => null) as FailedFile | null;
                if (errorBody && errorBody.failedToDisplay) {
                    throw new Error("File failed to be displayed.");
                } else {
                    throw new Error("Internal Server Error");
                }
            } else {
                throw new Error("Unexpected error");
            }
        }

        if (response.status === 200) {
            if (fileType == 'XML' && needProcessing) {
                return await response.json();
            }
            switch (fileType) {
                case 'XML':
                case 'XBRL':
                case 'TXT':
                case 'HTML':
                case 'XHTML':
                case 'ESEF_ZIP':
                    return await response.text();
                case 'PDF':
                    return await response.blob();
                case 'CSV':
                case 'JSON':
                    return await response.json();
                default:
                    throw new Error('Unsupported file type: ' + fileType);
            }
        }
    } catch (error: unknown) {
        if (error instanceof DOMException && error.name === "AbortError") {
            return;
        }
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error("Failed to fetch the file. Please try again.");
        }
    } finally {
        controller = null;
    }
}

export async function downloadDataset(datasetId: string): Promise<boolean> {
    try {
        const url = `${downloadServiceUrl}/v1/datasets/${datasetId}`;
        const response = await fetchWrapper(url);

        if (!response.ok) {
            await handleDatasetResponseError(response);
        }

        const zipBlob = await response.blob();
        const url_dataset = window.URL.createObjectURL(zipBlob);
        const a = document.createElement('a');
        a.href = url_dataset;
        a.download = datasetZipName(datasetId);
        document.body.appendChild(a);
        // TODO check alternative.
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url_dataset);
        return true;
    } catch (error) {
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error("Failed to fetch the dataset. Please try again.");
        }
    }
}

async function handleDatasetResponseError(response: Response): Promise<void> {
    if (response.status === 400) {
        throw new Error("Bad Request");
    } else if (response.status === 404) {
        throw new Error("The dataset is not available.");
    } else if (response.status === 429) {
        const errorBody = await response.json().catch(() => null) as FailedDataset | null;
        if (errorBody) {
            if (errorBody.numberPassed === false) {
                throw new Error("The number of datasets exceeds the allowed limit.");
            } else if (errorBody.sizePassed === false) {
                throw new Error("The volume of the dataset exceeds the allowed limit.");
            }
        } else {
            throw new Error("Too many requests. Please try again later.");
        }
    } else if (response.status === 500) {
        throw new Error("Internal Server Error");
    } else {
        throw new Error("Unexpected error");
    }
}

const datasetZipName = (datasetId: string): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${datasetId}_${year}-${month}-${day}.zip`;
  };

export async function downloadDatasetFile(datasetRealId: string, fileId: string, fileName: string): Promise<boolean> {
    const url = `${downloadServiceUrl}/v1/datasets/${datasetRealId}/files/${fileId}`;

    try {
        const response = await fetchWrapper(url);

        if (!response.ok) {
            if (response.status === 400) {
                throw new Error("Bad Request");
            } else if (response.status === 404) {
                throw new Error(`The file is not available.`);
            } else if (response.status === 500) {
                throw new Error("Internal Server Error");
            } else {
                throw new Error("Unexpected error");
            }
        }

        const fileBlob = await response.blob();
        const url_file = window.URL.createObjectURL(fileBlob);
        // TODO Check alternative.
        const a = document.createElement('a');
        a.href = url_file;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url_file);
        return true;
    } catch (error) {
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error("Failed to fetch the file. Please try again.");
        }
    }
}

export async function bulkDownloadRequestService(cartItems: Set<string>): Promise<BulkDownloadResult> {
    const url = `${downloadServiceUrl}/v1/datasets/bulk/downloadRequest`;
    const itemsArray = Array.from(cartItems);
    const requestBody = JSON.stringify({ items: itemsArray });

    try {
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        });

        let responseBody: BulkDownloadResponse | null = null;
        if (response.status === 200 || response.status === 404) {
            responseBody = await response.json();
        }

        if (!response.ok) {
            if (response.status === 400) {
                throw new Error("Bad Request");
            } else if (response.status === 404) {
                const validations = responseBody as BulkValidationResponse;
                const { validationNotCompleted, datasetsNotAvailable, numberOfDatasets, numberLimit, sizeOfDatasets, sizeLimit } = validations;
                if (validationNotCompleted) {
                    throw new Error("Something went wrong while validating your cart. Please try refreshing the page."); 
                }
                const setOfNotAvailableDatasets: Set<string> = datasetsNotAvailable ? new Set(datasetsNotAvailable) : new Set();
                if (numberOfDatasets > numberLimit) {
                    return {
                        isValid: false,
                        showAlert: true,
                        message: `The number of datasets added to the cart exceeds the allowed limit of ${numberLimit}.`,
                        error: false,
                        unavailableDatasets: setOfNotAvailableDatasets,
                    };
                } else if (datasetsNotAvailable && setOfNotAvailableDatasets.size > 0) {
                    console.log("Inside the unavailability");
                    return {
                        isValid: false,
                        showAlert: true,
                        message: "Some of the selected datasets are unavailable. Please remove them.",
                        error: false,
                        unavailableDatasets: setOfNotAvailableDatasets,
                    };
                } else if (sizeOfDatasets > sizeLimit) {
                    return {
                        isValid: false,
                        showAlert: true,
                        message: `The volume of datasets added to the cart exceeds the allowed limit of ${sizeLimit} MB.`,
                        error: false,
                        unavailableDatasets: setOfNotAvailableDatasets,
                    };
                }
            } else if (response.status === 500) {
                throw new Error("Internal Server Error");
            } else {
                throw new Error("Unexpected error");
            }
        }
 
        const { uuid, success } = responseBody as BulkUuidResponse;
        if (!uuid) {
            throw new Error('Request Uuid not available.');
        }
        if (!success) {
            throw new Error('Success was false.');
        }

        return {
            isValid: true,
            showAlert: false,
            message: null,
            error: false,
            unavailableDatasets: new Set(),
            uuid,
        };
    } catch (error) {
        let errorMsg = 'An error occurred while fetching data.'
        if (error instanceof Error) {
            errorMsg = error.message;
        }
        throw new Error(errorMsg);
    }
}

export async function dataForDATSSR(datasetRealId: string, fileId: string): Promise<DATSSRData> {
    const url = `${infoviewerUrl}/v1/datasets/process/${datasetRealId}/files/${fileId}`;
    const response = await fetchWrapper(url);

    if (response.ok) {
        const DATSSRData: DATSSRData = await response.json();
        return DATSSRData;
    } else {
        const errorMessage = `Error fetching data for DATSSR: ${response.status} - ${response.statusText}`;
        throw new Error(errorMessage);
    }
}

export async function drupalIconsConf(): Promise<DrupalDatasetIcon[]> {
    const url = `${infoviewerUrl}/v1/datasets/drupalIconsConfigurations`;
    const response = await fetchWrapper(url);

    if (response.ok) {
        const icons: DrupalIconsConfiguration=await response.json();        
        return icons.iconsConfigurations;
    } else {
        const errorMessage = `Error fetching drupal icons metadata: ${response.status} - ${response.statusText}`;
        throw new Error(errorMessage);
    }  
}

export async function cartValidation(
    cartItems: string[]
): Promise<CartValidations | null> {
    const url = `${downloadServiceUrl}/v1/datasets/validations/cart`;
    const itemsArray = Array.from(cartItems);
    
    const requestBody = JSON.stringify({ datasetIds: itemsArray });
    console.log("Request object: ",requestBody);
    try {
    const response = await fetchWrapper(url, {
        method: "POST",
        headers: {
        "Content-Type": "application/json",
        },
        body: requestBody,
    });

    if (!response.ok) {
        console.error("An error occurred fetching cart validation:", response.status);
        return null;
    }
    const data: CartValidations = await response.json();
    console.log("Response object: ",data);
    return data;
    } catch (error) {
    console.error("An error occurred while fetching cart validations:", error);
    return null;
    }
}

export async function fetchCsvFile(datasetRealId: string,fileId: string, pageNumber: number): Promise<CsvResponse> {
    const url = `${infoviewerUrl}/v1/datasets/process/csvFile`;

    const requestBody = {
      datasetRealId: datasetRealId,
      fileId: fileId,
      pageNumber: pageNumber,
    };
   
    try {
      const response = await fetchWrapper(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody), 
      });
    
      if (response.ok) {
        const csvContent: CsvResponse = await response.json();
        console.log("Content it receives",csvContent);
        return csvContent;
      } else {
        const errorMessage = `Error fetching csv content: ${response.status} - ${response.statusText}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      throw new Error(`Fetch  of csv content failed `);
    }
  }
import { SearchFilter } from "../../model/SearchFilter";
import { SearchResult } from "../../model/SearchResult";

import {backendSearchPublicUrl} from "../ServiceEndpoints";
import {fetchWrapper} from "../FetchWrapperService/FetchWrapper.ts";


export async function searchForm(searchFilter: SearchFilter, sortBy: string, itemsPerPage: number, pageNumber: number): Promise<SearchResult> {
    try {
        const url = `${backendSearchPublicUrl}/search?sortBy=${sortBy}&itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(searchFilter),
        });

        if (response.status === 200) {
            const data = await response.json();
            return data as SearchResult;
        } else {
            throw new Error("Request failed");
        }
    } catch (error) {
        console.log("API error " + error);
        return { results: [], totalCount: 0 };
    }
}


import {fetchWrapper} from "@/services/FetchWrapperService/FetchWrapper.ts";


export const fetchFilterItems = async () => {
    try {
        const url = import.meta.env.VITE_REACT_AZURE_GATEWAY_URL + `/func-esap/history-of-submission/getfilteritems`;
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const fetchResults = async () => {
    try {
        const url =
            `${import.meta.env.VITE_REACT_AZURE_GATEWAY_URL}/func-esap/history-of-submission/getresults`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(null), // Pass the filter here
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const fetchDetails = async (fileId: string | null) => {
    try {
        const url =
            `${import.meta.env.VITE_REACT_AZURE_GATEWAY_URL}/func-esap/history-of-submission/getdetails`;
        const response = await fetchWrapper(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({fileId}), // Pass the fileId in the request body
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const handleDownload = async (filename: string, downloadtype: string) => {
    try {
        console.log(`Downloading ${filename}`);
        const endpoint =
            `${import.meta.env.VITE_REACT_AZURE_GATEWAY_URL}/func-esap/history-of-submission/downloadfile`;
        const response = await fetchWrapper(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({filename, downloadtype}), // Pass the fileId in the request body
        });
        console.log(`${import.meta.env.VITE_REACT_AZURE_GATEWAY_URL}/func-esap/history-of-submission/downloadfile`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Retrieve filename from the `Content-Disposition` header, if available
        const contentDisposition = response.headers.get('content-disposition');
        const fileName = contentDisposition
            ? contentDisposition.split('filename=')[1].replace(/"/g, '')
            : 'downloaded-file.ext'; // Default fallback filename

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        // Clean up the URL object
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading file:", error);
    }
};

export const resendFeedback = async (filename: string) => {
    try {
        const endpoint =
            `${import.meta.env.VITE_REACT_AZURE_GATEWAY_URL}/func-esap/history-of-submission/resendfeedback?filename=${filename}`;
            /*`http://localhost:53968/api/resendfeedback?filename=${filename}`;*/

        console.log(endpoint);
        const response = await fetch(endpoint);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching data-----:', error);
        throw error;
    }

};
import { DrupalDatasetIcon } from "../../../model/DatasetDetailsIcons";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { drupalIconsConf } from "../../../services/DatasetDetailsService/DatasetDetailsService";

interface DrupalIconsProps {
  eSealed?: string;
  hstrcData?: boolean;
  vlntry?: boolean;
}

const DrupalIcons: React.FC<DrupalIconsProps> = ({eSealed: eSealed, hstrcData: hstrclData, vlntry: vlntry }) => {
  const [icons, setIcons] = useState<DrupalDatasetIcon[]>([]);
  const [error, setError] = useState<string | null>(null);

  const loadDrupalIcons = async () => {
    const errorMessage = "Failed to fetch icons configurations.";
    try {
      const iconsData = await drupalIconsConf();
      setIcons(iconsData);
    } catch (err) {
      setError(errorMessage);
      console.error("Error fetching icons:", err);
    }
  };

  useEffect(() => {
    loadDrupalIcons();
  }, []);

  const renderIcons = () => {
    const iconElements: JSX.Element[] = [];

    const createIconElement = (key: string, icon: DrupalDatasetIcon) => (
      <OverlayTrigger key={key} placement="top" overlay={<Tooltip>{icon.fieldDisclaimer}</Tooltip>}>
        <div className="SRInfo py-2 px-2 text-info-emphasis">
          {icon.fieldDisplay === "Icon" ? (
            <i className={icon.fieldIconClass}></i>
          ) : (
            <small>{icon.fieldLabel}</small>
          )}
        </div>
      </OverlayTrigger>
    );

    if (eSealed != null) {
      const sealIcon = icons.find(icon => icon.name === "UI_SEAL");
      if (sealIcon) {
        iconElements.push(createIconElement("seal", sealIcon));
      }
    }

    if (hstrclData) {
      const historicalIcon = icons.find(icon => icon.name === "UI_HISTORICAL");
      if (historicalIcon) {
        iconElements.push(createIconElement("historical", historicalIcon));
      }
    }

    if (vlntry) {
      const voluntaryIcon = icons.find(icon => icon.name === "UI_VOLUNTARY");
      if (voluntaryIcon) {
        iconElements.push(createIconElement("voluntary", voluntaryIcon));
      }
    }

    return iconElements.length > 0 ? (
      <div className="d-flex justify-content-end align-items-center">
        {iconElements}
      </div>
    ) : null;
  };

  return (
    <>
      {!error ? renderIcons() : null}
    </>
  );
};

export default DrupalIcons;

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { ChevronDown, XIcon} from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList
} from "@/components/ui/command";
import {Separator} from "@/components/ui/separator.tsx";

/**
 * Variants for the single-select component to handle different styles.
 * Uses class-variance-authority (cva) to define different styles based on "variant" prop.
 */
const singleSelectVariants = cva("m-1 truncate shadow-none font-normal", {
    variants: {
        variant: {
            default:
                "border-foreground/10 text-foreground bg-card hover:bg-card/80",
            secondary:
                "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
            destructive:
                "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
            inverted: "inverted",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

/**
 * Custom ref type extending HTMLButtonElement with the `clear` method.
 */
export interface SingleSelectRef extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    clear: () => void;
}

/**
 * Props for Select component
 */
export interface SingleSelectProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof singleSelectVariants> {
    /**
     * An array of option objects to be displayed in the single-select component.
     * Each option object has a label, value, and an optional icon.
     */
    options: {
        label: string;
        value: string;
    }[];

    /**
     * Callback function triggered when the selected value changes.
     * Receives the new selected value.
     */
    onValueChange: (value: string) => void;

    /** The default selected value when the component mounts. */
    defaultValue?: string;

    /**
     * Placeholder text to be displayed when no value is selected.
     * Optional, defaults to "Select option".
     */
    placeholder?: string;

    /**
     * The modality of the popover. When set to true, interaction with outside elements
     * will be disabled and only popover content will be visible to screen readers.
     * Optional, defaults to false.
     */
    modalPopover?: boolean;

    /**
     * If true, renders the single-select component as a child of another component.
     * Optional, defaults to false.
     */
    asChild?: boolean;

    /**
     * Additional class names to apply custom styles to the single-select component.
     * Optional, can be used to add custom styles.
     */
    className?: string;
}

export const Select = React.forwardRef<SingleSelectRef, SingleSelectProps>(
    (
        {
            options,
            onValueChange,
            variant,
            defaultValue = "",
            placeholder = "Select option",
            modalPopover = false,
            asChild = false,
            className,
            ...props
        },
        ref
    ) => {
        const [selectedValue, setSelectedValue] = React.useState<string>(defaultValue);
        const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

        const handleSelect = (option: string) => {
            setSelectedValue(option);
            onValueChange(option);
            setIsPopoverOpen(false);
        };

        const handleClear = () => {
            setSelectedValue("");
            onValueChange("");
        };

        React.useImperativeHandle(ref, () => ({
            clear: handleClear,
            ...props,
        }));

        const handleTogglePopover = () => {
            setIsPopoverOpen((prev) => !prev);
        };

        return (
            <Popover
                open={isPopoverOpen}
                onOpenChange={setIsPopoverOpen}
                modal={modalPopover}
            >
                <PopoverTrigger asChild>
                    <Button
                        ref={ref as React.Ref<HTMLButtonElement>}
                        {...props}
                        onClick={handleTogglePopover}
                        className={cn(
                            "flex p-1 grow rounded-md border min-h-10 h-auto items-center justify-between bg-inherit hover:bg-inherit [&_svg]:pointer-events-auto bg-white overflow-auto",
                            className
                        )}
                    >
                        {selectedValue ? (
                            <div className="flex justify-between items-center w-full">
                                <div className="flex truncate items-center">
                                    {options
                                        .filter((option) => option.value === selectedValue)
                                        .map((option) => {
                                            return (
                                                <span className="text-base border-foreground/10 text-foreground mx-2 truncate shadow-none font-normal">{option.label}</span>
                                            );
                                        })}
                                </div>
                                <div className="flex items-center justify-between">
                                    <XIcon
                                        aria-hidden='false'
                                        className="h-4 mx-2 cursor-pointer text-muted-foreground"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleClear();
                                        }}
                                    />
                                    <Separator
                                        orientation="vertical"
                                        className="flex min-h-6 h-full"
                                    />
                                    <ChevronDown className="h-4 mx-2 cursor-pointer text-muted-foreground"/>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between w-full mx-auto">
                                <span className="text-sm text-muted-foreground mx-3">
                                    {placeholder}
                                </span>
                                <ChevronDown className="h-4 cursor-pointer text-muted-foreground mx-2"/>
                            </div>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    className="w-auto p-0"
                    align="start"
                    onEscapeKeyDown={() => setIsPopoverOpen(false)}
                >
                    <Command>
                        <CommandInput placeholder="Search..." />
                        <CommandList>
                            <CommandEmpty>No results found.</CommandEmpty>
                            <CommandGroup>
                                {options.map((option) => {
                                    return (
                                        <CommandItem
                                            key={option.value}
                                            onSelect={() => handleSelect(option.value)}
                                            className="cursor-pointer"
                                        >
                                            <span>{option.label}</span>
                                        </CommandItem>
                                    );
                                })}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        );
    }
);

Select.displayName = "Select";